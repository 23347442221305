import {
  faArchive,
  faMap,
  faStore,
  faTable,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { BrowserRoutes } from 'core/enums';
import i18next from 'i18next';
import { ExpandableRouteProps } from './ExpandableRoute';

const GROWERS_SETTINGS_ROUTE_DISABLED =
  process.env.REACT_APP_ROUTE_RESELLERS_DISABLED === 'true';

const USERS_SETTINGS_ROUTE_DISABLED =
  process.env.REACT_APP_ROUTE_USERS_DISABLED === 'true';

export const drawerRoutes: Pick<
  ExpandableRouteProps,
  'title' | 'icon' | 'childrenRoutes'
>[] = [
  {
    title: i18next.t('drawer:admin_area'),
    icon: faArchive,
    childrenRoutes: [
      {
        label: i18next.t('drawer:growers_settings'),
        path: BrowserRoutes.GROWERS_SETTINGS,
        icon: faStore,
        disabled: GROWERS_SETTINGS_ROUTE_DISABLED,
      },
      {
        label: i18next.t('drawer:users_settings'),
        path: BrowserRoutes.USERS_SETTINGS,
        icon: faUsers,
        disabled: USERS_SETTINGS_ROUTE_DISABLED,
      },
      {
        label: i18next.t('drawer:live_monitoring'),
        path: BrowserRoutes.LIVE_MONITORING,
        icon: faMap,
      },
      {
        label: i18next.t('drawer:coupons'),
        path: BrowserRoutes.COUPONS,
        icon: faMap,
      },
      {
        label: i18next.t('drawer:systems'),
        path: BrowserRoutes.SYSTEMS,
        icon: faMap,
      },
    ],
  },
  {
    title: i18next.t('drawer:agronomist_area'),
    icon: faArchive,
    childrenRoutes: [
      {
        label: i18next.t('drawer:crop_models'),
        path: BrowserRoutes.CROP_MODELS,
        icon: faTable,
      },
    ],
  },
];
