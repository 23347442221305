import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { initGqlClient } from 'gql/client';
import { SnackbarProvider } from 'notistack';
import styled from 'styled-components';
import {
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';
import StylesProvider from '@mui/styles/StylesProvider';
import Routes from 'components/Routes';
import GlobalStyles from 'components/UI/GlobalStyles';
import Notifier from 'components/Notifier';
import { muiTheme } from 'components/UI/theme';
import { PrimeReactProvider, PrimeReactContext } from 'primereact/api';
import ErrorBoundary from 'components/UI/ErrorBoundary';

/**
 * fix bugs in latest version of notistack
 */
const FixedSnackbarProvider = styled(SnackbarProvider)`
  margin: 0.25rem 0;
  pointer-events: all;
`;

const apolloClient = initGqlClient();

function App() {
  return (
    <ApolloProvider client={apolloClient}>
      <PrimeReactProvider>
        <BrowserRouter>
          <StyledEngineProvider>
            <MuiThemeProvider theme={muiTheme}>
              <StylesProvider injectFirst>
                <GlobalStyles />
                <FixedSnackbarProvider maxSnack={3}>
                  <Notifier />
                  <ErrorBoundary>
                    <Routes />
                  </ErrorBoundary>
                </FixedSnackbarProvider>
              </StylesProvider>
            </MuiThemeProvider>
          </StyledEngineProvider>
        </BrowserRouter>
      </PrimeReactProvider>
    </ApolloProvider>
  );
}

export default App;
