import api from 'Api/ApiMethods';
import i18next from 'i18next';
import { makeAutoObservable } from 'mobx';
import { getAxiosErrorMessage } from 'utils/httpErrorsHelpers';
import { TreeExpandedKeysType } from 'primereact/tree';
import { TreeNode } from 'primereact/treenode';
import { RootStore } from './rootStore';

export interface Coupon {
  code: string;
  name: string;
  validFromUTC: number;
  validToUTC: number;
  growerId: number;
  maxTransactions: number;
  params: {
    eligibleCountries: string[];
  };
}

export interface CustomTreeNode<T> extends TreeNode {
  data?: T;
  key: string;
  label?: string;
  icon?: string | null;
  className?: string;
}

export class CouponsStore {
  rootStore: RootStore = {} as RootStore;
  coupons: Coupon[] = [];
  expandedTreeNodes: TreeExpandedKeysType = {};
  selectedTreeNode: CustomTreeNode<Coupon> | null = null;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  getAllCoupons = async () => {
    this.coupons = [];
    const response = await api.getCoupons();
    this.setCoupons(response);
  };

  resetCoupons = () => {
    this.coupons = [];
  };

  setCoupons = (coupons: Coupon[]) => {
    this.coupons = coupons;
  };

  createCoupon = async (coupon: Coupon) => {
    try {
      const result = await api.postCoupon(coupon);
      this.coupons.push(result);
      this.setSelectedTreeNode(this.createNewTreeNode(result));
      return result;
    } catch (error) {
      this.errorToast(this.getErrorMessage(error));
      return false;
    }
  };

  createNewTreeNode = (coupon: Coupon) => ({
    key: coupon?.code,
    label: coupon?.name,
    data: coupon,
    icon: null,
    className: '',
  });

  setSelectedTreeNode = (node: CustomTreeNode<Coupon> | null) => {
    this.selectedTreeNode = node;
  };

  generateTreeNodes = (coupons: Coupon[]) => {
    const growersChildren =
      coupons?.map((couponItem) => ({
        key: couponItem?.code,
        label: couponItem?.name,
        data: couponItem,
        icon: null,
        className: '',
      })) || [];

    return growersChildren as CustomTreeNode<Coupon>[];
  };

  get dataTree() {
    return this.generateTreeNodes(this.coupons);
  }

  private successToast(message: string): void {
    this.rootStore?.snackBarStore.showToast({
      detail: message,
      severity: 'success',
      summary: 'Success',
    });
  }

  errorToast = (message: string) => {
    this.rootStore?.snackBarStore.showToast({
      detail: message,
    });
  };

  private getErrorMessage(error: unknown): string {
    return (
      getAxiosErrorMessage(error) ?? i18next.t('errors:something_went_wrong')
    );
  }

  get selectedCouponId(): number {
    return Number(this.selectedTreeNode?.data?.code);
  }

  get selectedTreeNodeKey() {
    return this.selectedTreeNode?.key || null;
  }
}
