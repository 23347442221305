import React, { FC, memo, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { Wrapper } from '@googlemaps/react-wrapper';
import { theme } from 'components/UI';
import Dialog from 'components/UI/Dialog';
import Grid from 'components/UI/Grid';
import { GMap, GMapClass } from 'components/shared/GMap/GMap';

const { REACT_APP_GOOGLE_MAPS_API_KEY } = process.env;

const StyledGmap = styled(GMap) <{ small: boolean }>`
  min-height: ${(p) => (p.small ? '50vh' : '630px')};
  width: ${(p) => (p.small ? '50vw' : '100%')};
`;

const GmapContainer = styled(Grid)`
  padding: 1rem;
`;

function getArrayBounds(polyArray: google.maps.Polygon[]) {
  const bounds = new google.maps.LatLngBounds();
  let path;
  let paths;
  for (let polys = 0; polys < polyArray.length; polys += 1) {
    paths = polyArray[polys].getPaths();
    for (let i = 0; i < paths.getLength(); i += 1) {
      path = paths.getAt(i);
      for (let ii = 0; ii < path.getLength(); ii += 1) {
        bounds.extend(path.getAt(ii));
      }
    }
  }

  return bounds;
}

type Coordinate = {
  lat: number;
  lng: number;
};

interface MapDialogProps {
  visible: boolean;
  coordinates: Coordinate[];
  onHide: () => void;
  dialogTitle?: string;
  showClose?: boolean;
  small?: boolean;
}

const PolygonMapDialog: FC<MapDialogProps> = ({
  visible = false,
  coordinates,
  onHide,
  dialogTitle,
  showClose = true,
  small = false,
}) => {
  const gmap = useRef<(GMapClass & { map: google.maps.Map }) | null>(null);
  const options: google.maps.MapOptions = useMemo(
    () => ({
      zoomControl: true,
      mapTypeControl: true,
      scaleControl: true,
      streetViewControl: true,
      rotateControl: true,
      fullscreenControl: true,
      mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
        mapTypeIds: ['roadmap', 'terrain'],
      },
      zoom: 12,
    }),
    [],
  );

  const overlays = useMemo(
    () => [
      new google.maps.Polygon({
        paths: coordinates,
        strokeOpacity: 0.5,
        strokeWeight: 1,
        fillColor: theme.color.link,
        fillOpacity: 0.35,
      }),
    ],
    [coordinates],
  );

  const onMapReady = (e: { map: google.maps.Map }) => {
    e.map.fitBounds(getArrayBounds([overlays[0]]));
  };

  return (
    <Dialog
      onClose={onHide}
      open={visible}
      maxWidth="lg"
      dialogTitle={dialogTitle}
      showClose={showClose}
      fullWidth={!small}
    >
      <GmapContainer>
        <StyledGmap
          ref={gmap}
          overlays={overlays}
          options={options}
          small={small}
          onMapReady={onMapReady}
        />
      </GmapContainer>
    </Dialog>
  );
};

const MapDialog: FC<MapDialogProps> = (props) => {
  return (
    <Wrapper
      apiKey={REACT_APP_GOOGLE_MAPS_API_KEY || ''}
      render={() => <div />}
    >
      <PolygonMapDialog {...props} />
    </Wrapper>
  );
};

export default memo(MapDialog);
