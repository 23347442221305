import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import Page from 'components/shared/Page';
import { useStores } from 'stores/hooks/hooks';
import Loading from 'components/UI/Loading/Loading';
import Splitter from 'components/shared/Splitter';
import Grid from 'components/UI/Grid';
import CropModelTable from './CropTable/CropModelTable';
import ModelDetails from './ModelDetails/ModelDetails';

const CropModelContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 100%;
`;

const CropModel: FC = observer(() => {
  const { cropModelStore } = useStores();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    cropModelStore.initData().then(() => {
      setIsLoading(false);
    });

    return () => {
      cropModelStore.setProtocols([]);
      cropModelStore.setSelectedRows([]);
    };
  }, [cropModelStore]);

  return (
    <Page>
      <Grid direction="column" align="center" justify="center" flex="1">
        {isLoading ? (
          <Loading />
        ) : (
          <Splitter>
            <div style={{ height: '100%' }}>
              <CropModelTable />
            </div>
            <ModelDetails />
          </Splitter>
        )}
      </Grid>
    </Page>
  );
});

export default CropModel;
