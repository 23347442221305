import React, { ChangeEvent, FC, useCallback, useMemo } from 'react';
import Input, { Validation } from 'components/UI/Input/Input';
import { useTranslation } from 'react-i18next';
import { JSONSchema } from 'stores/types/types';
import Select from 'components/UI/Select';
import styled from 'styled-components';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { SettingType, tableKeys } from './ResellerMassEdit.utils';
import { checkError } from '../PlotManagementDialog/PlotSettingsManagementDialog/PlotSettingsManagementDialog.utils';

const StyledInput = styled(Input)`
  /* input {
    padding: 10px;
  } */
`;

const StyledSelect = styled(Select)`
  min-height: 40px;
  border: none;

  .p-dropdown-item {
    min-height: 32px;
  }
`;

const getFieldData = (
  dssSchema: JSONSchema | undefined,
  settingsSchema: JSONSchema | undefined,
  key: string,
) => {
  if (!dssSchema || !settingsSchema) {
    return undefined;
  }

  const schemaType = tableKeys.find((tableKey) => tableKey.key === key)?.type;
  if (!schemaType) {
    return undefined;
  }

  if (schemaType === SettingType.dss) {
    if (
      typeof dssSchema?.properties?.[key] !== 'boolean' &&
      dssSchema?.properties?.[key].type !== 'object'
    )
      return {
        type: dssSchema?.properties?.[key]?.type,
        property: dssSchema?.properties?.[key],
      };
  }

  if (schemaType === SettingType.settings) {
    if (
      typeof settingsSchema?.properties?.[key] !== 'boolean' &&
      settingsSchema?.properties?.[key].type !== 'object'
    )
      return {
        type: settingsSchema?.properties?.[key]?.type,
        property: settingsSchema?.properties?.[key],
      };
  }

  return undefined;
};

type SettingsFormInput = {
  value: any;
  dssSchema?: JSONSchema;
  settingsSchema?: JSONSchema;
  formKey: string;
  handleChangeValue: (value: any, isError: boolean, key: string) => void;
};

const MassEditInput: FC<SettingsFormInput> = (props) => {
  const { dssSchema, settingsSchema, value, handleChangeValue, formKey } =
    props;

  const { t } = useTranslation('settings_form');
  const { t: growerT } = useTranslation('grower');

  // Select options for current boolean fields (might change when actual boolean fields appear and we will just show a checkbox)
  const selectOptions = [
    { label: ' ', value: undefined },
    { label: growerT('manual'), value: false },
    { label: growerT('auto'), value: true },
  ];

  const fieldInfo = useMemo(
    () => getFieldData(dssSchema, settingsSchema, formKey),
    [dssSchema, settingsSchema, formKey],
  );

  const isError = checkError(value, fieldInfo?.property, t);

  const handleChange = (
    event: ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => {
    const hasError = !!checkError(event.target.value, fieldInfo?.property, t)
      ?.isError;

    if (fieldInfo?.type === 'number') {
      handleChangeValue(Number(event.target.value), hasError, formKey);
    }

    if (fieldInfo?.type === 'integer') {
      handleChangeValue(parseInt(event.target.value, 10), hasError, formKey);
    }

    if (fieldInfo?.type === 'string') {
      handleChangeValue(event.target.value, hasError, formKey);
    }

    if (fieldInfo?.type === 'boolean') {
      // Uncheck this if moving to use actual checkboxes
      handleChangeValue(
        (event as ChangeEvent<HTMLInputElement>).target.checked,
        false,
        formKey,
      );
    }
  };

  const handleChangeSelect = (event: DropdownChangeEvent) => {
    handleChangeValue(event.target.value, false, formKey);
  };

  const inputValidation: Validation = useCallback(
    (text = '') => {
      const isValid = !isError.isError;
      const errorMessage = ``;

      return {
        isValid,
        message: isValid ? '' : errorMessage,
      };
    },
    [isError.isError],
  );

  if (fieldInfo?.type === 'number' || fieldInfo?.type === 'integer') {
    return (
      <StyledInput
        validation={inputValidation}
        type="number"
        value={value}
        onChange={handleChange}
        variant="standard"
      />
    );
  }

  if (fieldInfo?.type === 'boolean') {
    // return <Checkbox onChange={handleChange} checked={!!value} />;
    return (
      <StyledSelect
        value={value}
        options={selectOptions}
        onChange={handleChangeSelect}
      />
    );
  }

  return null;
};

export default MassEditInput;
