import { observer } from 'mobx-react';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Column } from 'primereact/column';
import { useTranslation } from 'react-i18next';
import Grid from 'components/UI/Grid';
import Button from 'components/UI/Button';
import Loading from 'components/UI/Loading/Loading';
import { useStores } from 'stores/hooks/hooks';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { DataTable } from 'components/shared/DataTable/DataTable';
import {
  DataTable as PrimeReactDataTable,
  DataTableStateEvent,
} from 'primereact/datatable';
import styled from 'styled-components';
import { MultiSelect } from 'components/UI/MultiSelect/MultiSelect';
import { IProtocol } from 'models/protocol';
import { ServiceLevel, Plot, TimeZone, SoilType } from 'models/plot';
import {
  GetServiceLevelOptionLabel,
  GetTimeZoneOptionLabel,
  PlantingDateBody,
  SoilTypeOptionLabel,
} from './Components';

const StyledPlotBody = styled(Grid)`
  max-height: 100%;
`;

const StyledInputContainer = styled(Grid)`
  max-width: 20%;
  padding-top: 0.5rem; /* compensate for clipping select label */
`;

const lookForwardSize = 200; // must be a divisible and bigger any of these [10, 40, 200] to prevent entering a page with missing items

interface Filters {
  timeZone: string[];
  serviceLevel: string[];
  soilTypeId: number[];
}

interface PlotProps {
  selectedRows: IProtocol[];
}

const dataTableKeys: { [str: string]: keyof Plot } = {
  growerName: 'growerName',
  plotName: 'plotName',
  plotId: 'plotId',
  plantedAt: 'plantedAt',
};

const Plots: FC<PlotProps> = ({ selectedRows }) => {
  const { cropModelStore, snackBarStore } = useStores();
  const dataTable = useRef<PrimeReactDataTable | null>(null);

  const { t } = useTranslation('crop_models');
  const [plotFiltersLoading, setPlotFiltersLoading] = useState(true);
  const filtersRef = useRef<null | Filters>(null);

  const [serviceLevels, setServiceLevels] = useState<ServiceLevel[]>([]);
  const [selectedServiceLevels, setSelectedServiceLevels] = useState<
    ServiceLevel[]
  >([]);

  const [timeZones, setTimeZones] = useState<TimeZone[]>([]);
  const [selectedTimeZones, setSelectedTimeZones] = useState<TimeZone[]>([]);

  const [soilTypes, setSoilTypes] = useState<SoilType[]>([]);
  const [selectedSoilType, setSelectedSoilType] = useState<SoilType[]>([]);

  const handleServiceLevelChange = useCallback((e: any) => {
    setSelectedServiceLevels(e.value || []);
  }, []);

  const handleTimeZonesChange = useCallback((e: any) => {
    setSelectedTimeZones(e.value || []);
  }, []);

  const handleSoilTypeChange = useCallback((e: any) => {
    setSelectedSoilType(e.value || []);
  }, []);

  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 1,
    totalRecords: 0,
  });

  const [allData, setAllData] = useState<Plot[]>([]);
  const [currentData, setCurrentData] = useState<Plot[]>([]);

  const [tableData, setTableData] = useState({
    loading: false,
  });

  const onPage = useCallback(
    async (e: DataTableStateEvent) => {
      setLazyParams((p) => ({ ...p, ...e }));
      const currentFilters = filtersRef.current;
      if (!allData[e.first] && currentFilters) {
        setTableData((p) => {
          p.loading = true;
          return { ...p };
        });

        cropModelStore
          .getPlot({
            offset: e.first,
            cnt: lookForwardSize,
            ...currentFilters,
            modelId: selectedRows[0]?.protocolHeader.protocolId,
          })
          .then((res) => {
            setAllData((p) => {
              for (
                let i = 0;
                i < lookForwardSize && i < res.paginatedCollection.length;
                i += 1
              ) {
                p[e.first + i] = res.paginatedCollection[i];
              }

              setCurrentData(res.paginatedCollection.slice(0, e.rows));
              return [...p];
            });

            setTableData((prev) => {
              prev.loading = false;
              return { ...prev };
            });
          })
          .catch((err) => {
            if (err instanceof Error) {
              snackBarStore.showToast({ detail: err.message });
            }

            setTableData((prev) => {
              prev.loading = false;
              return { ...prev };
            });
          });
      } else {
        setCurrentData(allData.slice(e.first, e.first + e.rows));
      }
    },
    [allData, cropModelStore, selectedRows, snackBarStore],
  );

  const onClickSearch = useCallback(async () => {
    setTableData((p) => {
      p.loading = true;
      return { ...p };
    });

    filtersRef.current = {
      timeZone: selectedTimeZones.map((el) => el.timezone),
      serviceLevel: selectedServiceLevels.map((el) => el.serviceLevel),
      soilTypeId: selectedSoilType?.map((el) => el.soilId) || [],
    };

    cropModelStore
      .getPlot({
        offset: 0,
        cnt: lookForwardSize,
        ...filtersRef.current,
        modelId: selectedRows[0]?.protocolHeader.protocolId,
      })
      .then((res) => {
        const newData = new Array(res.totalCount).fill(null);
        for (let i = 0; i < res.totalCount; i += 1) {
          newData[i] = res.paginatedCollection[i];
        }

        setLazyParams((p) => {
          const newParams = {
            ...p,
            totalRecords: res.totalCount,
            first: 0,
          };

          setAllData(newData);
          setCurrentData(newData.slice(newParams.first, newParams.rows));
          setTableData((prev) => {
            prev.loading = false;
            return { ...prev };
          });

          return newParams;
        });
      })
      .catch((err) => {
        if (err instanceof Error) {
          snackBarStore.showToast({ detail: err.message });
        }

        setTableData((p) => {
          p.loading = false;
          return { ...p };
        });
      });
  }, [
    cropModelStore,
    selectedServiceLevels,
    selectedTimeZones,
    selectedSoilType,
    selectedRows,
    snackBarStore,
  ]);

  // on selected row change
  useEffect(() => {
    setTimeZones([]);
    setSelectedServiceLevels([]);
    setSelectedSoilType([]);
    setSelectedTimeZones([]);
    setSoilTypes([]);
    setServiceLevels([]);
    setAllData([]);
    setCurrentData([]);
    setPlotFiltersLoading(true);
    if (selectedRows.length) {
      cropModelStore
        .getPlotFilters(selectedRows[0]?.protocolHeader.protocolId)
        .then((res) => {
          setTimeZones(res.plotsByTimeZone);
          setSoilTypes(res.plotsBySoilType);
          setServiceLevels(res.plotsByServiceLevel);
          setPlotFiltersLoading(false);
        })
        .catch((err) => {
          if (err instanceof Error) {
            snackBarStore.showToast({ detail: err.message });
          }

          setPlotFiltersLoading(false);
        });
    }
  }, [selectedRows, cropModelStore, snackBarStore]);

  const searchDisabled =
    !selectedTimeZones.length &&
    !selectedSoilType.length &&
    !selectedServiceLevels.length;

  if (plotFiltersLoading) {
    return <Loading />;
  }

  return (
    <StyledPlotBody direction="row" gap="1rem" align="start" flex={1}>
      <StyledInputContainer align="stretch" gap="1rem" flex="1">
        <MultiSelect
          value={selectedServiceLevels}
          onChange={handleServiceLevelChange}
          options={serviceLevels}
          optionLabel="serviceLevel"
          itemTemplate={GetServiceLevelOptionLabel}
          filter
          label={t('service_level')}
          showClear
        />
        <MultiSelect
          value={selectedTimeZones}
          onChange={handleTimeZonesChange}
          options={timeZones}
          optionLabel="timezone"
          itemTemplate={GetTimeZoneOptionLabel}
          label={t('time_zone')}
          filter
          showClear
        />
        <MultiSelect
          value={selectedSoilType}
          onChange={handleSoilTypeChange}
          options={soilTypes}
          optionLabel="soilName"
          itemTemplate={SoilTypeOptionLabel}
          filter
          showClear
          label={t('soil_type')}
        />
        <Grid align="start">
          <Button
            color="primary"
            onClick={onClickSearch}
            disabled={searchDisabled}
          >
            {t('search')}&nbsp;
            <ArrowRightAltIcon />
          </Button>
        </Grid>
      </StyledInputContainer>
      <Grid flex="4" alignSelf="stretch">
        <DataTable
          ref={dataTable}
          scrollable
          resizableColumns
          value={currentData}
          paginator
          lazy
          loading={tableData.loading}
          first={lazyParams.first}
          onPage={onPage}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          rows={lazyParams.rows}
          showGridlines
          rowsPerPageOptions={[10, 40, 200]}
          totalRecords={lazyParams.totalRecords}
        >
          <Column field={dataTableKeys.growerName} header={t('grower')} />
          <Column field={dataTableKeys.plotName} header={t('plot')} />
          <Column field={dataTableKeys.plotId} header={t('plot_id')} />
          <Column
            field={dataTableKeys.plantedAt}
            header={t('planting_date')}
            body={PlantingDateBody}
          />
        </DataTable>
      </Grid>
    </StyledPlotBody>
  );
};

export default observer(Plots);
