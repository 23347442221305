import React from 'react';
import { MultiSelectPanelHeaderTemplateEvent } from 'primereact/multiselect';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Checkbox, CheckboxProps } from 'primereact/checkbox';
import styled from 'styled-components';
import Grid from 'components/UI/Grid';
import { useTranslation } from 'react-i18next';

const Container = styled(Grid)`
  padding: 0.5rem 1rem;

  .p-multiselect-filter-container {
    display: flex;
    margin-right: 0.5rem;
  }

  .p-multiselect-filter-icon {
    right: 10px;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  margin-right: 0.5rem;
`;

const StyledLabel = styled.label`
  width: 100%;
  cursor: pointer;
`;

export const MultiSelectHeader = ({
  checked,
  onChange,
  onCloseClick,
  filterElement,
}: MultiSelectPanelHeaderTemplateEvent) => {
  const { t } = useTranslation('general');

  return (
    <Container direction="row">
      <StyledCheckbox
        inputId="multiselect-checkbox-all"
        checked={checked}
        onChange={onChange as unknown as CheckboxProps['onChange']}
      />
      {filterElement ?? (
        <StyledLabel htmlFor="multiselect-checkbox-all">{t('all')}</StyledLabel>
      )}
      <IconButton aria-label="close" component="label" onClick={onCloseClick}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </Container>
  );
};
