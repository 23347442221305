import React, { FC, useState, useCallback, ReactElement } from 'react';
import Grid from 'components/UI/Grid';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeftSharp';
import ArrowRightIcon from '@mui/icons-material/ArrowRightSharp';
import styled from 'styled-components';
import IconButton from '@mui/material/IconButton';

const Container = styled(Grid)`
  height: 100%;
`;

export const CollapseLine = styled.div`
  position: relative;
  width: 1px;
  height: 100%;
  margin: 0 2rem;
  background-color: ${({ theme }) => theme.color.divider};
`;

export const CollapseButton = styled(IconButton)`
  position: absolute;
  right: -17.5px;
  top: 7%;
  padding: 0;

  &,
  &:hover {
    background-color: ${({ theme }) => theme.color.primeBorder};
  }
`;

const LeftSide = styled.div<{ collapsed: boolean; width: string }>`
  width: ${(p) => (p.collapsed ? '0' : p.width)};
  min-width: ${(p) => (p.collapsed ? '0' : p.width)};
  height: 100%;
`;

const RightSide = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  overflow: hidden;
`;

interface VerticalCollapseProps {
  leftComponent: ReactElement;
  rightComponent: ReactElement;
  leftSideWidth: string;
}

const VerticalCollapse: FC<VerticalCollapseProps> = ({
  leftComponent,
  rightComponent,
  leftSideWidth,
  ...rest
}) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

  const onCollapseChange = useCallback(() => {
    setIsCollapsed((prev) => !prev);
  }, []);

  return (
    <Container direction="row" align="start" {...rest}>
      <LeftSide
        className="left-side"
        collapsed={isCollapsed}
        width={leftSideWidth}
      >
        {leftComponent}
      </LeftSide>
      <CollapseLine>
        <CollapseButton
          aria-label="collapse"
          onClick={onCollapseChange}
          size="large"
        >
          {isCollapsed ? (
            <ArrowRightIcon fontSize="large" />
          ) : (
            <ArrowLeftIcon fontSize="large" />
          )}
        </CollapseButton>
      </CollapseLine>
      <RightSide className="right-side">{rightComponent}</RightSide>
    </Container>
  );
};

export default VerticalCollapse;
