import React, { FC } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import Grid from 'components/UI/Grid';
import { useStores } from 'stores/hooks/hooks';
import FeaturesModels from './FeaturesModels';
import FeaturesSensors from './FeaturesSensors';

const Container = styled(Grid)`
  height: 100%;
  width: 100%;
`;

const Features: FC = observer(() => {
  const { featuresStore } = useStores();
  const { selectedFeatures } = featuresStore;

  return (
    <Container flex={1}>
      <FeaturesModels />
      {!!selectedFeatures.length && <FeaturesSensors />}
    </Container>
  );
});

export default Features;
