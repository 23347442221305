import React, { FC, useCallback } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Tree, TreeEventNodeEvent } from 'primereact/tree';
import { TreeNode } from 'primereact/treenode';
import Grid from 'components/UI/Grid';
import Text from 'components/UI/Text';
import Tooltip from 'components/UI/Tooltip';
import { useStores } from 'stores/hooks/hooks';
import {
  TREE_NODE_HIDDEN_CLASS_NAME,
  TREE_NODE_INACTIVE_CLASS_NAME,
} from 'core/constants';
import { Coupon, CustomTreeNode } from 'stores/couponsStore';

interface TemplateProps {
  node: TreeNode;
}

const StyledTree = styled(Tree)`
  flex: 1;
  flex-direction: column;
  display: flex;
  overflow: auto;
  border: none;
  padding: 0;

  .p-tree-container {
    display: flex;
    flex-direction: column;
    overflow: visible;
    height: 100%;
    width: 100%;
  }

  .p-treenode {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .p-highlight {
    z-index: 1;
  }

  .p-treenode-label {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .p-treenode-children {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .p-treenode-content {
    width: 100%;
  }

  .pi-fw {
    text-align: start;
  }

  .${TREE_NODE_INACTIVE_CLASS_NAME} {
    .p-treenode-content {
      border-radius: 5px;
      background-color: ${({ theme }) => theme.color.palePink};
    }
  }

  .${TREE_NODE_HIDDEN_CLASS_NAME} {
    display: none;
  }
`;

const ResellersTreeContainer = styled(Grid)`
  height: 100%;
  width: 100%;
  padding: 1rem;
  background-color: ${({ theme }) => theme.color.white};
  border: ${({ theme }) => `1px solid ${theme.color.border}`};
`;

const Template: FC<TemplateProps> = ({ node }) => {
  const { label = '' } = node;
  return (
    <Tooltip arrow title={label} placement="top">
      <Text singleline>{label}</Text>
    </Tooltip>
  );
};

const CouponsTree: FC = observer(({ ...rest }) => {
  const { couponsStore } = useStores();
  const { t } = useTranslation('grower');

  if (!couponsStore.coupons) {
    return null;
  }

  const onSelect = useCallback(({ node }: TreeEventNodeEvent) => {
    const isSameNodeSelected = node.data.id === couponsStore.selectedCouponId;

    if (isSameNodeSelected) {
      return;
    }

    couponsStore.setSelectedTreeNode(node as CustomTreeNode<Coupon>);
  }, []);

  const onToggle = useCallback((e: TreeExpandedEvent) => {
    // resellersStore.setExpandedTreeNodes(e.value, mode);
    console.log('e', e);
  }, []);

  return (
    <ResellersTreeContainer align="stretch" overflow="auto" {...rest}>
      <StyledTree
        value={couponsStore.dataTree}
        filter
        selectionMode="single"
        nodeTemplate={(node) => <Template node={node} />}
        selectionKeys={couponsStore.selectedTreeNodeKey}
        onSelect={onSelect}
        onToggle={onToggle}
      />
    </ResellersTreeContainer>
  );
});

export default CouponsTree;
