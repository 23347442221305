import React, { FC, useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import DataTable from 'components/shared/DataTable';
import { Column } from 'components/shared/DataTable/DataTable';
import Grid from 'components/UI/Grid';
import Loading from 'components/UI/Loading/Loading';
import { useStores } from 'stores/hooks/hooks';
import { Paper } from '@mui/material';
import {
  HALF_SCREEN_HEIGHT,
  HALF_SCREEN_TABLE_TOP_HEIGHT,
  HIGHLIGHTED_ROW_CLASS_NAME,
} from 'core/constants';
import { FilterMatchMode } from 'primereact/api';
import { DataTableRowClickEvent } from 'primereact/datatable';
import useInactiveRowClassName from 'hooks/datatable/useInactiveRowClassName';
import { GrowerSystem } from 'models/grower';

const StyledGrid = styled(Grid)`
  display: flex;
  flex: unset;
  width: 100%;
  align-items: normal;
`;

const DataTableContainer = styled(Paper)`
  overflow: hidden;
  height: ${HALF_SCREEN_HEIGHT};
  max-height: ${HALF_SCREEN_HEIGHT};
`;

const GrowerSystems: FC = observer(() => {
  const { t } = useTranslation('grower');
  const { resellersStore, systemsStore } = useStores();
  const { allSystems, getGrowerSystemSensors } = systemsStore;
  const [selectedRowSystem, setSelectedRowSystem] =
    useState<GrowerSystem | null>(null);

  const [filters, setFilters] = useState({
    global: { value: '', matchMode: FilterMatchMode.CONTAINS },
    displayName: { value: '', matchMode: FilterMatchMode.CONTAINS },
    type: { value: '', matchMode: FilterMatchMode.CONTAINS },
    serial: { value: '', matchMode: FilterMatchMode.CONTAINS },
  });

  const onRowClick = useCallback(({ data }: DataTableRowClickEvent) => {
    setSelectedRowSystem(data as GrowerSystem);
    systemsStore.setSelectedSystem(data as GrowerSystem);
  }, []);

  const getSystemSensors = useCallback(
    async (growerId: number, systemId: number) => {
      getGrowerSystemSensors(growerId, systemId);
    },
    [],
  );

  useEffect(() => {
    if (systemsStore.selectedSystem?.id) {
      getSystemSensors(
        systemsStore.selectedSystem.growerId,
        systemsStore.selectedSystem.id,
      );
    }
  }, [
    getSystemSensors,
    systemsStore.selectedSystem,
    systemsStore.selectedSystemSensors,
  ]);

  if (allSystems.loading) {
    return <Loading />;
  }

  return (
    <StyledGrid flex={1}>
      <DataTableContainer elevation={3}>
        <DataTable
          id="GrowerSystemsTable"
          showGridlines
          resizableColumns
          value={allSystems.data.systems}
          selectionMode="single"
          scrollable
          scrollHeight={HALF_SCREEN_TABLE_TOP_HEIGHT}
          dataKey="id"
          emptyMessage={t('empty_message')}
          stripedRows
          filters={filters}
          selection={selectedRowSystem}
          onRowClick={onRowClick}
          // rowClassName={getRowClassName}
          filterDisplay="row"
          globalFilterFields={['displayName', 'type', 'serial']}
        >
          <Column field="displayName" header={t('name')} sortable filter />
          <Column field="type" header={t('type')} sortable filter />
          <Column field="serial" header={t('serial')} sortable filter />
        </DataTable>
      </DataTableContainer>
    </StyledGrid>
  );
});

export default GrowerSystems;
