import { observer } from 'mobx-react';
import Loading from 'components/UI/Loading/Loading';
import { useStores } from 'stores/hooks/hooks';
import { TabViewTabChangeEvent } from 'primereact/tabview';
import { useTranslation } from 'react-i18next';
import React, { FC, useCallback, useState } from 'react';
import styled from 'styled-components';
import { TabPanel, TabView } from 'components/UI/TabView';
import SystemDetails from './SystemDetails';
import SystemSensorsTable from './SystemSensorsTable';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex: 1;

  .p-tabview-panels {
    height: 100%;
  }
`;

const StyledTabView = styled(TabView)`
  .p-tabview-nav {
  }
`;

enum TabKey {
  Details = 0,
  Sensors = 1,
}

const SystemSensors: FC = observer(() => {
  const { t } = useTranslation('grower');
  const [tabIndex, setTabIndex] = useState(TabKey.Details);
  const {
    systemsStore: { selectedSystemSensors },
  } = useStores();

  const onTabChange = useCallback(({ index }: TabViewTabChangeEvent) => {
    setTabIndex(index);
  }, []);

  return (
    <Container>
      <StyledTabView
        activeIndex={tabIndex}
        renderActiveOnly
        onTabChange={onTabChange}
      >
        <TabPanel header={t('details')}>
          <SystemDetails />
        </TabPanel>
        <TabPanel header={t('sensors')}>
          {selectedSystemSensors.loading ? <Loading /> : <SystemSensorsTable />}
        </TabPanel>
      </StyledTabView>
    </Container>
  );
});

export default SystemSensors;
