import React, { FC, useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import Grid from 'components/UI/Grid';
import { useStores } from 'stores/hooks/hooks';
import {
  HALF_SCREEN_TABLE_BOTTOM_HEIGHT,
  HALF_SCREEN_TABLE_TOP_HEIGHT,
} from 'core/constants';
import { Paper } from '@mui/material';
import { Column, DataTable } from 'components/shared/DataTable/DataTable';
import { useTranslation } from 'react-i18next';
import {
  DataTableFilterMeta,
  DataTableRowClickEvent,
} from 'primereact/datatable';
import { FilterMatchMode } from 'primereact/api';

const Container = styled(Grid)`
  max-height: ${HALF_SCREEN_TABLE_BOTTOM_HEIGHT};
  overflow: scroll;
`;

const DataTableContainer = styled(Paper)`
  overflow: hidden;
  /* max-height: ${HALF_SCREEN_TABLE_TOP_HEIGHT}; */
`;

const FeaturesSensorsTable: FC = observer(() => {
  const { featuresStore } = useStores();
  const { selectedFeatureSensors } = featuresStore;
  const { t } = useTranslation('feature');
  const tableFilters: DataTableFilterMeta = {
    serial: { value: '', matchMode: FilterMatchMode.CONTAINS },
    displayName: { value: '', matchMode: FilterMatchMode.CONTAINS },
    latitude: { value: '', matchMode: FilterMatchMode.CONTAINS },
    longitude: { value: '', matchMode: FilterMatchMode.CONTAINS },
    'sensorType.group': {
      value: '',
      matchMode: FilterMatchMode.CONTAINS,
    },
    type: { value: '', matchMode: FilterMatchMode.CONTAINS },
  };

  const tableRows = useMemo(
    () => featuresStore.selectedFeatures[0].connectedSensors || [],
    [featuresStore.selectedFeatures],
  );

  const onRowClick = useCallback(
    ({ data }: DataTableRowClickEvent) => {
      featuresStore.setSelectedFeatureSensor(data);
    },
    [featuresStore],
  );

  return (
    <Container flex={1} display="block">
      <DataTableContainer elevation={3}>
        <DataTable
          id="FeatureTable"
          showGridlines
          resizableColumns
          value={JSON.parse(JSON.stringify(tableRows))}
          dataKey="id"
          scrollable
          emptyMessage={t('empty_message')}
          stripedRows
          filters={tableFilters}
          filterDisplay="row"
          scrollHeight={HALF_SCREEN_TABLE_BOTTOM_HEIGHT}
          selection={selectedFeatureSensors}
          selectionMode="single"
          onRowClick={onRowClick}
        >
          <Column field="serial" header={t('sensor')} sortable filter />
          <Column field="displayName" header={t('name')} sortable filter />
          <Column field="latitude" header={t('latitude')} sortable filter />
          <Column field="longitude" header={t('longitude')} sortable filter />
          <Column
            field="sensorType.group"
            header={t('group')}
            sortable
            filter
          />
          <Column field="type" header={t('type')} sortable filter />
        </DataTable>
      </DataTableContainer>
    </Container>
  );
});

export default FeaturesSensorsTable;
