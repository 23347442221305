import React, {
  FC,
  useCallback,
  useRef,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Paper } from '@mui/material';
import { Column } from 'components/shared/DataTable/DataTable';
import {
  DataTableRowClickEvent,
  DataTable as PrimeReactDataTable,
} from 'primereact/datatable';
import { Tooltip } from 'primereact/tooltip';
import { FilterMatchMode } from 'primereact/api';
import useInactiveRowClassName from 'hooks/datatable/useInactiveRowClassName';
import { useStores } from 'stores/hooks/hooks';
import Grid from 'components/UI/Grid';
import { HIGHLIGHTED_ROW_CLASS_NAME, SCROLL_HEIGHT } from 'core/constants';
import {
  GrowerType,
  ResellerTreeNode,
  TreeGrower,
  ResellerTreeMode,
} from 'models/grower';
import {
  getDataTableCheckboxFilterTemplate,
  getDataTableGrowerTypeColumnBody,
  getDataTableStatusColumnBody,
} from 'components/shared/DataTable/DataTableColumns';
import MultiselectDataTable, {
  MultiselectDataTableChangeEvent,
} from 'components/shared/MultiselectDataTable';
import {
  GrowerNumBody,
  HectaresBody,
  NameBody,
  PlotsBody,
} from './TableComponents';

const ResellersTableContainer = styled(Grid)`
  height: 100%;
  overflow: hidden;
`;

const DataTableWrapper = styled(Paper)`
  height: inherit;
  overflow: hidden;
`;

const ResellersTable: FC = observer(() => {
  const { resellersStore } = useStores();
  const { t } = useTranslation('grower');
  const dataTable = useRef<PrimeReactDataTable | null>(null);
  const [filters, setFilters] = useState({
    global: { value: '', matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    growerType: { value: null, matchMode: FilterMatchMode.CONTAINS },
    isActive: { value: null, matchMode: FilterMatchMode.EQUALS },
    numOfGrowers: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    plotCount: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    hectares: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });

  const checkActive = useCallback((grower: TreeGrower) => grower.isActive, []);
  const { getRowClassName } = useInactiveRowClassName({
    checkActive,
    rowClassName: HIGHLIGHTED_ROW_CLASS_NAME,
  });

  useEffect(() => {
    setFilters((prev) => {
      prev.global.value = resellersStore.globalFilter.trim();
      return { ...prev };
    });
  }, [resellersStore.globalFilter]);

  const onMultiselectChange = useCallback(
    ({ activeRow, selection }: MultiselectDataTableChangeEvent<TreeGrower>) => {
      resellersStore.setSelectedRows({
        activeRow,
        selection,
      });
    },
    [resellersStore],
  );

  const onRowDoubleClick = useCallback(
    (e: DataTableRowClickEvent) => {
      if (
        (e.data as TreeGrower | ResellerTreeNode).growerType ===
        GrowerType.Reseller
      ) {
        const foundChild = resellersStore.selectedTreeNode?.children.find(
          (el) => el.data === e.data,
        );

        const currentTreeNode = resellersStore.selectedTreeNode;
        if (foundChild && currentTreeNode) {
          if (foundChild.children.length) {
            resellersStore.addExpandedTreeNodes(
              foundChild.key as string,
              ResellerTreeMode.All,
            );
          }

          resellersStore.setSelectedTreeNode(foundChild, ResellerTreeMode.All);
        }
      }
    },
    [resellersStore],
  );

  const getStatusColumnBody = useCallback(
    (grower: TreeGrower) => getDataTableStatusColumnBody(grower, 'isActive'),
    [],
  );

  const getGrowerTypeColumnBody = useCallback(
    (grower: TreeGrower) =>
      getDataTableGrowerTypeColumnBody(grower, 'growerType'),
    [],
  );

  const tableValues = useMemo(
    () =>
      resellersStore.isInactiveNodesVisible
        ? resellersStore.tableValues
        : resellersStore.tableValues.filter((grower) => grower.isActive),
    [resellersStore.tableValues, resellersStore.isInactiveNodesVisible],
  );

  return (
    <ResellersTableContainer flex={1} display="block">
      <DataTableWrapper elevation={3}>
        <Tooltip position="top" target=".PrimeReactTableToolTip" />
        <MultiselectDataTable
          ref={dataTable}
          id="GrowersTable"
          showGridlines
          resizableColumns
          value={tableValues}
          scrollable
          scrollHeight={SCROLL_HEIGHT}
          activeRow={resellersStore.growerTableMultiSelection.activeRow}
          selection={resellersStore.growerTableMultiSelection.selection}
          dataKey="id"
          emptyMessage={t('empty_message')}
          stripedRows
          onMultiselectChange={onMultiselectChange}
          onRowDoubleClick={onRowDoubleClick}
          rowClassName={getRowClassName}
          filters={filters}
          filterDisplay="row"
          globalFilterFields={[
            'name',
            'growerType',
            'numOfGrowers',
            'plotCount',
            'hectares',
          ]}
          paginator
          rows={25}
        >
          <Column
            filter
            sortable
            field="name"
            header={t('name')}
            body={NameBody}
          />
          <Column
            filter
            sortable
            field="growerType"
            header={t('type')}
            body={getGrowerTypeColumnBody}
          />
          <Column
            filter
            sortable
            field="numOfGrowers"
            header={t('growers')}
            body={GrowerNumBody}
          />
          <Column
            sortable
            filter
            field="isActive"
            header={t('active')}
            body={getStatusColumnBody}
            filterElement={getDataTableCheckboxFilterTemplate}
          />
          <Column
            filter
            sortable
            field="plotCount"
            header={t('plots')}
            body={PlotsBody}
          />
          <Column
            filter
            sortable
            field="hectares"
            header={t('hectares')}
            body={HectaresBody}
          />
        </MultiselectDataTable>
      </DataTableWrapper>
    </ResellersTableContainer>
  );
});

export default ResellersTable;
