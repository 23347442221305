import React, {
  FC,
  useEffect,
  useState,
  useCallback,
  MouseEvent,
  useMemo,
} from 'react';
import { observer } from 'mobx-react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { TabPanel, TabView, TabViewTabChangeEvent } from 'primereact/tabview';
import { Paper } from '@mui/material';
import { TabViewMode } from 'core/enums';
import { ToggleBox, ToggleBoxProps } from 'components/shared/ToggleBox';
import Grid from 'components/UI/Grid';
import Text from 'components/UI/Text';
import Page from 'components/shared/Page';
import VerticalCollapse from 'components/shared/VerticalCollapse';
import Loading from 'components/UI/Loading/Loading';
import { useStores } from 'stores/hooks/hooks';
import { GrowerType, ResellerTreeMode } from 'models/grower';
import { AlertVariant } from 'models/alert';
import ResellersTree from '../Resellers/ResellersTree/ResellersTree';
import LiveMonitoringAlerts from './LiveMonitoringAlerts/LiveMonitoringAlerts';
import MenuBar from './MenuBar';

const StyledMarginGrid = styled(Grid)`
  margin: 1rem 1rem 0 1rem;
`;

const RightSideContainer = styled.div`
  padding: 1rem 1rem 1rem 0;
  width: 100%;
  height: 100%;
`;

const tableFiltersHeight = '50px';

const TabViewContainer = styled(Paper)`
  position: relative;
  width: 100%;
  height: 100%;

  .p-tabview,
  .p-tabview-panel {
    height: 100%;
  }

  .p-tabview-nav-container {
    height: ${tableFiltersHeight};
  }

  .p-tabview-panels {
    height: calc(100% - ${tableFiltersHeight});
    padding-left: 0;
  }
`;

const TreeTitleText = styled(Text)`
  color: ${({ theme }) => theme.color.text};
`;

const ToggleContainer = styled(Grid)`
  position: absolute;
  right: 1rem;
  top: 8px;
  z-index: 1;
`;

const TreeContainer = styled.div`
  height: calc(100vh - 32px);
  margin: 1rem;
  margin-right: 0;
  overflow: hidden;
`;

enum MonitoringTab {
  Technical = 0,
  Anomaly = 1,
  Agronomic = 2,
}

const LiveMonitoring: FC = observer(() => {
  const { t } = useTranslation('liveMonitoring');
  const { resellersStore, userStore } = useStores();
  const [activeTabIndex, setActiveTabIndex] = useState(MonitoringTab.Technical);
  const [tabViewMode, setTabViewMode] = useState<TabViewMode>(
    TabViewMode.Table,
  );

  const { resellerTreeLoading, selectedTreeNode } = resellersStore;
  const selectedNodeData = selectedTreeNode?.data;
  const treeTitle = selectedNodeData?.name;
  const isMapEnabled = resellersStore.isLowestTreeLevelSelected;

  useEffect(() => {
    resellersStore.initData();
    resellersStore.setAlertsMapVariant(AlertVariant.Technical);
    return () => {
      resellersStore.setResellerTree([]);
    };
  }, [resellersStore]);

  const onTabChange = useCallback(
    ({ index }: TabViewTabChangeEvent) => {
      setActiveTabIndex(index);
      if (index === MonitoringTab.Technical) {
        userStore.generateCoralogixError('Press Live Monitoring - Techincal');
      }

      if (index === MonitoringTab.Anomaly) {
        userStore.generateCoralogixError('Press Live Monitoring - Anomaly');
      }

      resellersStore.setAlertsMapVariant(
        index === MonitoringTab.Technical
          ? AlertVariant.Technical
          : AlertVariant.Agronomic,
      );
    },
    [resellersStore, userStore],
  );

  const handleTabViewChange = useCallback(
    (_event: MouseEvent<HTMLElement>, value: TabViewMode) => {
      setTabViewMode((prev) => value ?? prev);
    },
    [],
  );

  const tableViewOptions: ToggleBoxProps['options'] = useMemo(
    () => [
      { value: TabViewMode.Table, label: i18next.t('grower:table') },
      {
        value: TabViewMode.Map,
        label: i18next.t('grower:map'),
        disabled: !isMapEnabled,
      },
    ],
    [isMapEnabled],
  );

  useEffect(() => {
    if (!isMapEnabled) {
      setTabViewMode(TabViewMode.Table);
    }
  }, [isMapEnabled]);

  if (resellerTreeLoading) {
    return (
      <Page>
        <Loading />
      </Page>
    );
  }

  return (
    <Page>
      <Grid direction="column" align="stretch" justify="start" grow>
        <VerticalCollapse
          leftSideWidth="400px"
          leftComponent={
            <TreeContainer>
              <ResellersTree mode={ResellerTreeMode.All} />
            </TreeContainer>
          }
          rightComponent={
            <RightSideContainer>
              <TabViewContainer elevation={1}>
                <TabView
                  activeIndex={activeTabIndex}
                  renderActiveOnly={false}
                  onTabChange={onTabChange}
                >
                  <TabPanel header={t('technical')}>
                    <LiveMonitoringAlerts
                      viewMode={tabViewMode}
                      variant={AlertVariant.Technical}
                    />
                  </TabPanel>
                  <TabPanel header={t('anomaly')}>
                    <LiveMonitoringAlerts
                      viewMode={tabViewMode}
                      variant={AlertVariant.Anomaly}
                    />
                  </TabPanel>
                  <TabPanel header={t('agronomic')}>
                    <LiveMonitoringAlerts
                      viewMode={tabViewMode}
                      variant={AlertVariant.Agronomic}
                    />
                  </TabPanel>
                </TabView>
                <ToggleContainer direction="row" gap="1rem">
                  <TreeTitleText weight="bold">{treeTitle}</TreeTitleText>
                  <ToggleBox
                    value={tabViewMode}
                    options={tableViewOptions}
                    onChange={handleTabViewChange}
                  />
                </ToggleContainer>
              </TabViewContainer>
            </RightSideContainer>
          }
        />
      </Grid>
    </Page>
  );
});

export default LiveMonitoring;
