import React, {
  FC,
  useRef,
  useState,
  useEffect,
  useCallback,
  ChangeEvent,
  useMemo,
} from 'react';
import { observer } from 'mobx-react';
import { autorun } from 'mobx';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FilterMatchMode } from 'primereact/api';
import { SCROLL_HEIGHT_FULL_SCREEN } from 'core/constants';
import { useStores } from 'stores/hooks/hooks';
import { UserGrower } from 'models/user';
import Grid from 'components/UI/Grid';
import Select from 'components/UI/Select';
import {
  Column,
  PrimeReactDataTable,
} from 'components/shared/DataTable/DataTable';
import MultiselectDataTable, {
  MultiselectDataTableChangeEvent,
} from 'components/shared/MultiselectDataTable';
import { getDataTableUserRoleColumnBody } from 'components/shared/DataTable/DataTableColumns';
import { DataTableStateEvent } from 'primereact/datatable';
import MenuBar from './MenuBar';

const PermissionsContainer = styled(Grid)`
  height: 100%;
`;

export const UserPermissions: FC = observer(() => {
  const { t } = useTranslation('users');
  const { membersStore, snackBarStore } = useStores();
  const dataTable = useRef<PrimeReactDataTable | null>(null);
  const [filters, setFilters] = useState({
    global: { value: '', matchMode: FilterMatchMode.CONTAINS },
    id: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    resellerName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    userGrowerRole: { value: null, matchMode: FilterMatchMode.EQUALS },
  });

  const [totalRecords, setTotalRecords] = useState(
    membersStore.totalUserGrowersRecord,
  );

  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 15,
    page: 0,
  });

  const [loadLazyTimeout, setLoadLazyTimeout] = useState<NodeJS.Timeout | null>(
    null,
  );

  const onGlobalFilterChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      filters.global.value = (event.target.value || '').trim();
      setFilters({ ...filters });
    },
    [filters],
  );

  const onMultiselectChange = useCallback(
    ({ selection, activeRow }: MultiselectDataTableChangeEvent<UserGrower>) => {
      membersStore.setSelectedRowsMemberGrower({
        activeRow,
        selection,
      });
    },
    [membersStore],
  );

  useEffect(() => {
    const cleanAutorun = autorun(() => {
      if (membersStore.selectedReseller.length) {
        dataTable.current?.filter(
          membersStore.selectedReseller,
          'resellerName',
          'in',
        );
      } else {
        dataTable.current?.reset();
      }
    });

    return () => {
      cleanAutorun();
    };
  }, [membersStore]);

  const RoleRowFilterTemplate = useCallback(
    (options: any) => {
      return (
        <Select
          value={options.value}
          options={membersStore.growersRoles.map((role) => role.name)}
          onChange={(e) => options.filterApplyCallback(e.value)}
        />
      );
    },
    [membersStore.growersRoles],
  );

  const getUserRoleColumnBody = useCallback(
    (user: UserGrower) =>
      getDataTableUserRoleColumnBody(user, 'userGrowerRole'),
    [],
  );

  const onPage = useCallback(
    async (e: DataTableStateEvent) => {
      if (e.page === lazyParams.page) {
        return;
      }

      setLazyParams((p) => ({ ...p, ...e }));
    },
    [lazyParams],
  );

  const loadLazyData = useCallback(() => {
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }

    setLoadLazyTimeout(
      setTimeout(() => {
        const userGrowers = membersStore.getMembersGrowersAssigned(
          lazyParams.first,
          lazyParams.rows,
        );

        userGrowers
          .then((data) => {
            setTotalRecords(data);
          })
          .catch((err) => {
            if (err instanceof Error) {
              snackBarStore.showToast({ detail: err.message });
            }
          });
      }),
    );
  }, [
    lazyParams.first,
    lazyParams.rows,
    loadLazyTimeout,
    membersStore,
    snackBarStore,
  ]);

  useEffect(() => {
    loadLazyData();
  }, [lazyParams]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PermissionsContainer>
      <MultiselectDataTable
        showGridlines
        resizableColumns
        scrollable
        stripedRows
        id="UserPermissionsTable"
        dataKey="id"
        variant="strict"
        ref={dataTable}
        value={membersStore.memberGrowers}
        scrollHeight={SCROLL_HEIGHT_FULL_SCREEN}
        emptyMessage={i18next.t('general:no_data_found')}
        selection={membersStore.memberGrowerTableMultiSelection.selection}
        activeRow={membersStore.memberGrowerTableMultiSelection.activeRow}
        header={<MenuBar onGlobalFilterChange={onGlobalFilterChange} />}
        onMultiselectChange={onMultiselectChange}
        filters={filters}
        filterDisplay="row"
        globalFilterFields={['id', 'name', 'resellerName', 'userGrowerRole']}
        paginator
        onPage={onPage}
        first={lazyParams.first}
        rows={lazyParams.rows}
        totalRecords={totalRecords}
        lazy
      >
        <Column field="id" header={t('grower_id')} sortable filter />
        <Column field="name" header={t('grower')} sortable filter />
        <Column field="resellerName" header={t('reseller')} sortable filter />
        <Column
          field="userGrowerRole"
          header={t('role')}
          sortable
          filter
          showFilterMenu={false}
          filterElement={RoleRowFilterTemplate}
          body={getUserRoleColumnBody}
        />
      </MultiselectDataTable>
    </PermissionsContainer>
  );
});
