import Page from 'components/shared/Page';
import { observer } from 'mobx-react';
import React, { FC, useCallback, useEffect, useState } from 'react';
import Grid from 'components/UI/Grid';
import Splitter from 'components/shared/Splitter';
import { useStores } from 'stores/hooks/hooks';
import Loading from 'components/UI/Loading/Loading';
import UsersTable from './UsersTable';
import UserDetails from './UserDetails';

const Users: FC = observer(() => {
  const { membersStore } = useStores();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    membersStore.initData().then(() => {
      setIsLoading(false);
    });

    return () => {
      membersStore.setMembers([]);
      membersStore.setSelectedRows({ selection: [], activeRow: null });
    };
  }, [membersStore]);

  const getUserPreferences = useCallback(
    async () => membersStore.getUserPreferences(),
    [membersStore],
  );

  useEffect(() => {
    getUserPreferences();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page>
      <Grid direction="column" align="center" justify="center" flex="1">
        {isLoading ? (
          <Loading />
        ) : (
          <Splitter>
            <div style={{ height: '100%' }}>
              <UsersTable />
            </div>
            <UserDetails />
          </Splitter>
        )}
      </Grid>
    </Page>
  );
});

export default Users;
