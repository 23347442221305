import React, { FC } from 'react';
import styled from 'styled-components';
import { DataTable as PrimeReactDataTable } from 'primereact/datatable';
import {
  ColumnBodyType,
  ColumnProps,
  Column as PrimeReactColumn,
} from 'primereact/column';
import Text from 'components/UI/Text';
import Tooltip from 'components/UI/Tooltip';
import { resolveFieldData } from 'utils/functions';
import { observer } from 'mobx-react';
import { HIGHLIGHTED_ROW_CLASS_NAME } from 'core/constants';

/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/ban-ts-comment */

export const DataTable = styled(PrimeReactDataTable)`
  &&& {
    .p-filter-column {
      padding: 0.5rem 0.5rem;
    }

    .p-resizable-column {
      padding: 1.5rem 0.5rem;
    }

    .p-hidden-space {
      display: none;
    }
  }

  .${HIGHLIGHTED_ROW_CLASS_NAME} {
    td {
      background-color: ${({ theme }) => theme.color.palePink};
    }
  }
`;

export const GenericBody: FC<ColumnBodyType> = (
  data: any,
  props: CustomColumnProps,
  ..._parameters: any
) => {
  const { field, altField } = props;
  return <GenericBodyContainer data={data} field={field} altField={altField} />;
};

export const GenericBodyContainer: FC<{
  data: any;
  field: string | undefined;
  altField: string | undefined;
}> = observer(({ data, field, altField }) => {
  const text: string =
    resolveFieldData(field, data) || resolveFieldData(altField, data) || '';

  return (
    <Tooltip arrow title={text} placement="top">
      <Text size="md" singleline>
        {text}
      </Text>
    </Tooltip>
  );
});

export interface CustomColumnProps extends ColumnProps {
  altField?: string;
  bodyProps?: any;
}

export const Column: FC<CustomColumnProps> = () => {
  return null;
};

/* this exist to add a default 'body' prop to the DataTable Columns
  primereact ignores the Columns altogether and looks directly into their props, <Column/> never gets rendered.
*/

Column.defaultProps = {
  // @ts-ignore
  ...PrimeReactColumn.defaultProps,
  footer: null,
  body: GenericBody,
};

export { PrimeReactDataTable };
