import React, {
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { List } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import ListItemIcon from '@mui/material/ListItemIcon';
import { useStores } from 'stores/hooks/hooks';
import Grow from 'components/UI/Grow';
import Text from 'components/UI/Text';
import Grid from 'components/UI/Grid';
import { theme } from 'components/UI';
import {
  iconStyle,
  Logout,
  MinimizeAble,
  OverflowText,
  Spacer,
  StyledDrawer,
  StyledListItem,
} from './Styles';
import DrawerList from './DrawerList';
import Head from './Head';
import packageInfo from '../../../../package.json';

const UserIconContainer = styled(ListItemIcon)`
  min-width: auto;
  padding-right: 1rem;
`;

const Drawer: FC = () => {
  const { userStore } = useStores();
  const [isPinned, setIsPinned] = useState(true);
  const [hideText, setHideText] = useState(false); // state used to decide to whether display text in drawer (for example, if drawer minimized we shouldn't display text.)
  const [isDrawerHovered, setIsDrawerHovered] = useState(false); // if mouse over drawer
  const drawerRef = useRef<HTMLDivElement | null>(null);
  const isMinimized = !isDrawerHovered && !isPinned;
  const { t } = useTranslation('drawer');
  useEffect(() => {
    const ref = drawerRef.current;
    const transitionEndEvent = (event: TransitionEvent) => {
      if (event.propertyName === 'width') {
        const cur = isMinimized;
        if (cur) {
          setHideText(true);
        }
      }
    };

    const transitionStartEvent = (event: TransitionEvent) => {
      if (event.propertyName === 'width') {
        setHideText(false);
      }
    };

    if (ref) {
      ref.addEventListener('transitionstart', transitionStartEvent);
      ref.addEventListener('transitionend', transitionEndEvent);
    }

    return () => {
      ref?.removeEventListener('transitionend', transitionEndEvent);
      ref?.removeEventListener('transitionstart', transitionStartEvent);
    };
  }, [isMinimized]);

  /**
   * shrink drawer if screen resized to below 1024px
   */
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 1024 && isPinned) {
        setIsPinned(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isPinned]);

  const handleMouseEnter = useCallback(() => {
    setIsDrawerHovered(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsDrawerHovered(false);
  }, []);

  const handleLogout = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      userStore.logOut();
    },
    [userStore],
  );

  return (
    <>
      <Spacer isPinned={isPinned} />
      <StyledDrawer
        ref={drawerRef}
        isMinimized={isMinimized}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Head
          isPinned={isPinned}
          isMinimized={isMinimized}
          setIsPinned={setIsPinned}
          hideText={hideText}
        />
        <DrawerList isMinimized={isMinimized} />
        <Grow />
        <List
          className="drawer-footer"
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <Grid direction="column" align="center" justify="start">
            <Grid direction="row" align="center" justify="start">
              <UserIconContainer>
                <PersonIcon style={iconStyle} />
              </UserIconContainer>
              <OverflowText $hideText={hideText}>
                {`${userStore.user?.firstName} ${userStore.user?.lastName}`.trim()}
              </OverflowText>
            </Grid>
            <Grow />
            <MinimizeAble isMinimized={isMinimized}>
              <Logout onClick={handleLogout} variant="text">
                <Text textcolor={theme.color.link} size="md">
                  {t('logout')}
                </Text>
              </Logout>
            </MinimizeAble>
          </Grid>
          <StyledListItem>
            <OverflowText $hideText={false}>
              Ver: {packageInfo.version}
            </OverflowText>
          </StyledListItem>
        </List>
      </StyledDrawer>
    </>
  );
};

export default observer(Drawer);
