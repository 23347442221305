import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Paper } from '@mui/material';
import { FilterMatchMode } from 'primereact/api';
import { useStores } from 'stores/hooks/hooks';
import { ResellerUsers as ResellerUserType } from 'models/grower';
import { HIGHLIGHTED_ROW_CLASS_NAME, SCROLL_HEIGHT } from 'core/constants';
import useInactiveRowClassName from 'hooks/datatable/useInactiveRowClassName';
import { Column } from 'components/shared/DataTable/DataTable';
import Grid from 'components/UI/Grid';
import MultiselectDataTable, {
  MultiselectDataTableChangeEvent,
} from 'components/shared/MultiselectDataTable';
import { getDataTableUserRoleColumnBody } from 'components/shared/DataTable/DataTableColumns';

const ResellerUsersContainer = styled(Grid)`
  height: 100%;
  overflow: hidden;
`;

const DataTableWrapper = styled(Paper)`
  height: 100%;
  overflow: hidden;
`;

const ResellerUsers: FC = observer(() => {
  const { t } = useTranslation('grower');
  const { resellersStore } = useStores();
  const { selectedRows, resellerUsers } = resellersStore;
  const [filters, setFilters] = useState({
    global: { value: '', matchMode: FilterMatchMode.CONTAINS },
    firstName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    lastName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    email: { value: null, matchMode: FilterMatchMode.CONTAINS },
    userRole: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const checkActive = useCallback((user: ResellerUserType) => user.active, []);
  const { getRowClassName } = useInactiveRowClassName({
    checkActive,
    rowClassName: HIGHLIGHTED_ROW_CLASS_NAME,
  });

  useEffect(() => {
    setFilters((prev) => {
      prev.global.value = resellersStore.globalFilter.trim();
      return { ...prev };
    });
  }, [resellersStore.globalFilter]);

  useEffect(() => {
    const growerID = resellersStore.selectedTreeNode?.data?.id || 0;

    if (growerID) {
      resellersStore.getResellerUsers(growerID);
    }
  }, [selectedRows, resellersStore]);

  const onMultiselectChange = useCallback(
    ({
      activeRow,
      selection,
    }: MultiselectDataTableChangeEvent<ResellerUserType>) => {
      resellersStore.setSelectedRowsUsers({
        activeRow,
        selection,
      });
    },
    [resellersStore],
  );

  const activeResellerUsers: ResellerUserType[] = useMemo(
    () => resellerUsers.filter((user) => user.active),
    [resellerUsers],
  );

  const getUserRoleColumnBody = useCallback(
    (user: ResellerUserType) =>
      getDataTableUserRoleColumnBody(user, 'userRole'),
    [],
  );

  return (
    <ResellerUsersContainer flex={1} display="block">
      <DataTableWrapper elevation={3}>
        <MultiselectDataTable
          showGridlines
          resizableColumns
          scrollable
          stripedRows
          id="ResellerUsersTable"
          dataKey="id"
          filterDisplay="row"
          value={activeResellerUsers}
          selection={resellersStore.growerUsersTableMultiSelection.selection}
          activeRow={resellersStore.growerUsersTableMultiSelection.activeRow}
          scrollHeight={SCROLL_HEIGHT}
          emptyMessage={t('empty_message')}
          filters={filters}
          globalFilterFields={['firstName', 'lastName', 'email', 'userRole']}
          rowClassName={getRowClassName}
          onMultiselectChange={onMultiselectChange}
        >
          <Column sortable filter field="firstName" header={t('first_name')} />
          <Column sortable filter field="lastName" header={t('last_name')} />
          <Column sortable filter field="email" header={t('email')} />
          <Column
            sortable
            filter
            field="userRole"
            header={t('role')}
            body={getUserRoleColumnBody}
          />
        </MultiselectDataTable>
      </DataTableWrapper>
    </ResellerUsersContainer>
  );
});

export default ResellerUsers;
