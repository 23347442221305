import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import i18next from 'i18next';
import styled from 'styled-components';
import { Menubar, MenubarProps } from 'primereact/menubar';
import ConfirmDialog from 'components/UI/ConfirmDialog';
import { Column } from 'components/shared/DataTable/DataTable';
import { useTranslation } from 'react-i18next';
import Grid from 'components/UI/Grid';
import Button from 'components/UI/Button';
import Text from 'components/UI/Text';
import Loading from 'components/UI/Loading/Loading';
import { useStores } from 'stores/hooks/hooks';
import { Paper, DialogContent, DialogActions, Divider } from '@mui/material';
import { FilterMatchMode } from 'primereact/api';
import { ActiveSensor, EditableSensor } from 'models/sensor';
import { ActionMode, DialogCloseAction } from 'models/shared';
import {
  IS_APP_SYSTEMS_DISABLED,
  HALF_SCREEN_TABLE_BOTTOM_HEIGHT,
} from 'core/constants';
import { TableMultiSelection } from 'stores/types/types';
import {
  checkIsEditableSensorsValid,
  getDefaultEditableSensors,
  getPlotsMergedNames,
} from 'utils/plotSensorsUtils';
import { getDataTableMultiSelection } from 'utils/datatableUtils';
import { DataTableFilterMeta } from 'primereact/datatable';
import Dialog from 'components/UI/Dialog';
import MultiselectDataTable, {
  MultiselectDataTableChangeEvent,
} from 'components/shared/MultiselectDataTable';
import {
  EditableSensorPlotOption,
  SensorsEditableRowOptions,
  SensorsEditableTable,
} from './SensorsEditableTable';
import { getSensorPlotOptions } from './Systems.utils';

const Container = styled(Paper)`
  height: inherit;
  margin-top: 0.5rem;
`;

const DataTableContainer = styled(Grid)`
  max-height: ${HALF_SCREEN_TABLE_BOTTOM_HEIGHT};
  overflow: scroll;
`;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    height: 50vh;
    overflow: hidden;
  }
`;

const StyledDialogActions = styled(DialogActions)`
  padding: 2rem 1rem 0.5rem;
`;

const StyledSensorsEditableTable = styled(SensorsEditableTable)`
  margin: 1rem;
  margin-top: 0.5rem;
`;

const ButtonsContainer = styled(Grid)`
  width: 100%;
`;

const getPlotColumnBody = ({ plots }: ActiveSensor) => {
  return getPlotsMergedNames(plots);
};

const tableFilters: DataTableFilterMeta = {
  'sensor.sensorType.name': { value: '', matchMode: FilterMatchMode.CONTAINS },
  'sensor.displayName': { value: '', matchMode: FilterMatchMode.CONTAINS },
  'sensor.serial': { value: '', matchMode: FilterMatchMode.CONTAINS },
  'sensor.type': { value: '', matchMode: FilterMatchMode.CONTAINS },
  'sensor.latitude': { value: '', matchMode: FilterMatchMode.CONTAINS },
  'sensor.longitude': { value: '', matchMode: FilterMatchMode.CONTAINS },
  plotName: { value: '', matchMode: FilterMatchMode.CONTAINS },
  'params.id': { value: '', matchMode: FilterMatchMode.CONTAINS },
};

interface TableSensorRow extends ActiveSensor {
  sensorId: number;
  plotName: string;
}

const SystemSensorsTable: FC = observer(() => {
  const { t } = useTranslation('grower');
  const { systemsStore, resellersStore } = useStores();
  const { growerSystemSensors, resellerPlots } = resellersStore;
  const [selectedRows, setSelectedRows] = useState<
    TableMultiSelection<TableSensorRow>
  >({ selection: [], activeRow: null });

  const [updatedRows, setUpdatedRows] = useState<EditableSensor[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isRemoveSensorsConfirmVisible, setRemoveSensorsConfirmVisible] =
    useState<boolean>(false);

  const [editTableMode, setEditTableMode] = useState<ActionMode>();
  const [editTableVisible, setEditTableVisible] = useState<boolean>(false);
  const growerId = resellersStore.selectedGrowerId;
  const system = resellersStore.selectedRowsSystems[0];
  const systemId = system?.id as number;
  const isEditMode = editTableMode === ActionMode.EDIT;
  const isUnusedSensorsLoading = resellersStore.growerUnusedSensors.loading;
  const isFetchSensorsError =
    !isEditMode && !!resellersStore.growerUnusedSensors.error;

  const fetchGrowerUnusedSensors = useCallback(() => {
    resellersStore.getGrowerUnusedSensors(growerId, systemId);
  }, [resellersStore, growerId, systemId]);

  const showEditTable = useCallback(
    (mode: ActionMode) => {
      if (mode === ActionMode.CREATE) {
        fetchGrowerUnusedSensors();
      }

      setEditTableMode(mode);
      setEditTableVisible(true);
    },
    [fetchGrowerUnusedSensors],
  );

  const hideEditTable = useCallback(() => {
    setEditTableVisible(false);
  }, []);

  const onMultiselectChange = useCallback(
    ({
      activeRow,
      selection,
    }: MultiselectDataTableChangeEvent<TableSensorRow>) => {
      setSelectedRows({ activeRow, selection });
    },
    [],
  );

  const selectedSensors = useMemo(
    () => updatedRows.filter(({ details }) => details.selected),
    [updatedRows],
  );

  const handleEditTableSuccess = useCallback(() => {
    setUpdatedRows([]);
    setSelectedRows({ selection: [], activeRow: null });
    setLoading(false);
    setEditTableVisible(false);
  }, []);

  const tableSelectedRows: TableSensorRow[] = useMemo(
    () => getDataTableMultiSelection(selectedRows),
    [selectedRows],
  );

  const updateSensors = useCallback(() => {
    setLoading(true);
    resellersStore
      .updateGrowerSensors(growerId, selectedSensors)
      .then(handleEditTableSuccess)
      .catch(() => setLoading(false));
  }, [selectedSensors, resellersStore, growerId, handleEditTableSuccess]);

  const createSensors = useCallback(() => {
    setLoading(true);
    resellersStore
      .addGrowerSensors(growerId, systemId, selectedSensors)
      .then(handleEditTableSuccess)
      .catch(() => setLoading(false));
  }, [
    selectedSensors,
    resellersStore,
    growerId,
    systemId,
    handleEditTableSuccess,
  ]);

  const removeSensors = useCallback(() => {
    setLoading(true);
    resellersStore
      .removeGrowerSensors(
        growerId,
        systemId,
        tableSelectedRows.map(({ sensor }) => sensor),
      )
      .then(() => {
        setLoading(false);
        setSelectedRows({ selection: [], activeRow: null });
        setRemoveSensorsConfirmVisible(false);
      })
      .catch(() => setLoading(false));
  }, [resellersStore, growerId, systemId, tableSelectedRows]);

  const handleRemoveSensorsSubmit = useCallback(
    (value: DialogCloseAction) => {
      if (value === DialogCloseAction.Confirmed) {
        removeSensors();
      } else {
        setRemoveSensorsConfirmVisible(false);
      }
    },
    [removeSensors],
  );

  const plotOptions: EditableSensorPlotOption[] = useMemo(
    () => getSensorPlotOptions(resellerPlots),
    [resellerPlots],
  );

  const editSensorOptions: SensorsEditableRowOptions = useMemo(
    () => ({
      plot: plotOptions,
    }),
    [plotOptions],
  );

  const menuBarActions: MenubarProps['model'] = useMemo(
    () => [
      {
        label: i18next.t('general:add'),
        icon: 'pi pi-fw pi-plus',
        command: () => showEditTable(ActionMode.CREATE),
        disabled: IS_APP_SYSTEMS_DISABLED,
      },
      {
        label: i18next.t('general:edit'),
        icon: 'pi pi-fw pi-pencil',
        disabled: IS_APP_SYSTEMS_DISABLED || !selectedRows.activeRow,
        command: () => showEditTable(ActionMode.EDIT),
      },
      {
        label: i18next.t('general:remove'),
        icon: 'pi pi-fw pi-trash',
        disabled: IS_APP_SYSTEMS_DISABLED || !tableSelectedRows.length,
        command: () => setRemoveSensorsConfirmVisible(true),
      },
    ],
    [selectedRows.activeRow, showEditTable, tableSelectedRows],
  );

  const editTableTitle: string = useMemo(() => {
    const systemName = resellersStore.selectedRowsSystems[0]?.displayName;
    return isEditMode
      ? t('edit_system_sensors', { system: systemName })
      : t('add_sensors_to_system', { system: systemName });
  }, [isEditMode, resellersStore.selectedRowsSystems, t]);

  const selectedEditableSensors: EditableSensor[] = useMemo(
    () =>
      tableSelectedRows.map(({ sensor, plots }) => {
        const attachedPlotIds = new Set(plots.map((plot) => plot.id));
        return {
          sensor,
          details: {
            selected: true,
            plots: resellerPlots.filter((plot) => attachedPlotIds.has(plot.id)),
          },
        };
      }),
    [resellerPlots, tableSelectedRows],
  );

  const unusedEditableSensors: EditableSensor[] = useMemo(
    () => getDefaultEditableSensors(resellersStore.growerUnusedSensors.data),
    [resellersStore.growerUnusedSensors.data],
  );

  const tableRows: TableSensorRow[] = useMemo(
    () =>
      growerSystemSensors.data.map((growerSensor) => ({
        ...growerSensor,
        sensorId: growerSensor.sensor.id,
        plotName: getPlotsMergedNames(growerSensor.plots),
      })),
    [growerSystemSensors.data],
  );

  useEffect(() => {
    systemsStore.getSensorTypes();
  }, [systemsStore]);

  const isSensorsValid = useMemo(
    () => checkIsEditableSensorsValid(selectedSensors),
    [selectedSensors],
  );

  return (
    <DataTableContainer display="block" overflow="scroll">
      <Container>
        <MultiselectDataTable
          id="SystemSensorsTable"
          showGridlines
          resizableColumns
          value={tableRows}
          scrollable
          scrollHeight={HALF_SCREEN_TABLE_BOTTOM_HEIGHT}
          dataKey="sensorId"
          emptyMessage={t('empty_message')}
          stripedRows
          header={<Menubar model={menuBarActions} />}
          filters={tableFilters}
          filterDisplay="row"
          selection={selectedRows.selection}
          activeRow={selectedRows.activeRow}
          onMultiselectChange={onMultiselectChange}
        >
          <Column
            field="sensor.sensorType.name"
            header={t('sensor')}
            sortable
            filter
          />
          <Column
            field="sensor.displayName"
            header={t('name')}
            sortable
            filter
          />
          <Column field="sensor.serial" header={t('serial')} sortable filter />
          <Column field="sensor.type" header={t('type')} sortable filter />
          <Column
            field="sensor.latitude"
            header={t('latitude')}
            sortable
            filter
          />
          <Column
            field="sensor.longitude"
            header={t('longitude')}
            sortable
            filter
          />
          <Column
            field="plotName"
            body={getPlotColumnBody}
            header={t('plot')}
            sortable
            filter
          />
          <Column
            field="sensor.params.id"
            header={t('orderId')}
            sortable
            filter
          />
        </MultiselectDataTable>
        <StyledDialog
          open={editTableVisible}
          onClose={hideEditTable}
          maxWidth="lg"
          dialogTitle={editTableTitle}
          titleWeight="bold"
        >
          {isEditMode ? (
            <DialogContent>
              <StyledSensorsEditableTable
                system={system}
                sensors={selectedEditableSensors}
                sensorTypes={systemsStore.sensorTypes.data}
                options={editSensorOptions}
                onChange={setUpdatedRows}
                tableHeight="34vh"
                showActiveColumn
                mode={ActionMode.EDIT}
              />
            </DialogContent>
          ) : (
            <DialogContent>
              {isUnusedSensorsLoading ? (
                <Grid direction="row" justify="center">
                  <Loading />
                </Grid>
              ) : (
                <StyledSensorsEditableTable
                  system={system}
                  sensors={unusedEditableSensors}
                  sensorTypes={systemsStore.sensorTypes.data}
                  options={editSensorOptions}
                  onChange={setUpdatedRows}
                  tableHeight="34vh"
                  showActiveColumn
                />
              )}
            </DialogContent>
          )}
          <Divider />
          <StyledDialogActions>
            <ButtonsContainer
              direction="row"
              justify={isFetchSensorsError ? 'between' : 'end'}
            >
              {isFetchSensorsError && (
                <Button
                  onClick={fetchGrowerUnusedSensors}
                  disabled={isUnusedSensorsLoading}
                  loading={isUnusedSensorsLoading}
                  type="button"
                >
                  <Text size="md">{t('try_again')}</Text>
                </Button>
              )}
              <Button
                onClick={isEditMode ? updateSensors : createSensors}
                disabled={
                  isLoading || !selectedSensors.length || !isSensorsValid
                }
                loading={isLoading}
                type="submit"
              >
                <Text size="md">
                  {i18next.t(isEditMode ? 'general:apply' : 'general:add')}
                </Text>
              </Button>
            </ButtonsContainer>
          </StyledDialogActions>
        </StyledDialog>
      </Container>
      {isRemoveSensorsConfirmVisible && (
        <ConfirmDialog
          backText={i18next.t('general:cancel')}
          buttonText={i18next.t('general:remove')}
          isOpen={isRemoveSensorsConfirmVisible}
          title={i18next.t('dialog:confirm_dialog_title', { action: 'Delete' })}
          message={i18next.t('dialog:confirm_dialog_action_message', {
            action: 'delete',
            entity: 'sensors',
          })}
          onClose={handleRemoveSensorsSubmit}
          loading={isLoading}
          disabled={isLoading}
        />
      )}
    </DataTableContainer>
  );
});

export default SystemSensorsTable;
