import React from 'react';
import { storesContext } from '../contexts/storesContext';

export const useStores = () => React.useContext(storesContext);

/* eslint-disable */

// eslint-disable-next-line react-hooks/exhaustive-deps
export const useMountEffect = (fn: any) => React.useEffect(fn, []);

export const useUpdateEffect = (fn: any, deps?: any[]) => {
    const mounted = React.useRef(false);
    return React.useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
            return;
        }

        return fn && fn();
    }, deps);
};
/* eslint-enable */
