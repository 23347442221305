import { IconButton, ListItemButton } from '@mui/material';
import styled from 'styled-components';
import { theme } from 'components/UI';
import Button from 'components/UI/Button';
import Row from 'components/UI/Row';
import Text from 'components/UI/Text';

export const DRAWER_MAX_WIDTH = '210px';
export const DRAWER_MIN_WIDTH = '4rem';

export const iconStyle = {
  width: '2rem',
  height: '2rem',
  color: theme.color.antiqueTin,
};

export const faIconStyle = {
  paddingBlock: '0.25rem',
  width: '2rem',
  height: '2rem',
  color: theme.color.antiqueTin,
};

export const faIconSelectedStyle = {
  ...faIconStyle,
  color: theme.color.leafGreen,
};

export const StyledRow = styled(Row)`
  overflow: hidden;
`;

export const OverflowText = styled(Text) <{ $hideText: boolean }>`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
  color: ${(p) => p.theme.color.antiqueTin};
  display: ${(p) => (p.$hideText ? 'none' : 'inline-block')};
`;

export const StyledIconButton = styled(IconButton)`
  padding: 0.5rem;
`;

export const StyledLogo = styled.img`
  width: 2rem;
  height: 2rem;
`;

export const Logout = styled(Button)``;

export const StyledListItem = styled(ListItemButton) <{
  depth?: number;
  $isMinimized?: boolean;
}>`
  padding: ${(p) =>
    p.divider ? 0 : `0.5rem 0 0.5rem ${p.$isMinimized ? 0 : p.depth}rem`};
  overflow: hidden;
  transition: padding 0.5s ease-out;
`;

StyledListItem.defaultProps = { depth: 0 };

export const StyledDrawer = styled.div<{ isMinimized: boolean }>`
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  padding: 1rem 0;
  width: ${(p) => (!p.isMinimized ? DRAWER_MAX_WIDTH : DRAWER_MIN_WIDTH)};
  background-color: ${(p) => p.theme.color.white};
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: absolute;
  z-index: 1000;
  overflow: hidden;

  &:hover {
    width: ${DRAWER_MAX_WIDTH};
  }

  .drawer-footer,
  .drawer-header {
    padding: 0 1rem;
  }
`;

export const Spacer = styled.div<{ isPinned: boolean }>`
  display: flex;
  min-width: ${(p) => (p.isPinned ? DRAWER_MAX_WIDTH : DRAWER_MIN_WIDTH)};
  width: ${(p) => (p.isPinned ? DRAWER_MAX_WIDTH : DRAWER_MIN_WIDTH)};
`;

export const MinimizeAble = styled(Row) <{
  isMinimized: boolean;
  withOpacity?: boolean;
}>`
  opacity: ${(p) => (p.withOpacity && p.isMinimized ? '0' : '1')};
  width: ${(p) => (p.isMinimized ? '0px' : 'auto')};
  display: flex;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const NavigationListItem = styled(ListItemButton) <{
  isMinimized: boolean;
  isSelected: boolean;
  depth?: number;
}>`
  padding-right: 1rem;
  padding-left: ${(p) =>
    p.isMinimized ? '1rem' : `calc(1rem + ${p.depth ?? 0}rem)`};
  padding-top: ${(p) => (p.isMinimized ? '0.5rem' : '1rem')};
  padding-bottom: ${(p) => (p.isMinimized ? '0.5rem' : '1rem')};
  background-color: ${(p) =>
    !p.isMinimized && p.isSelected
      ? `${p.theme.color.selectedTableRowLime}`
      : 'transparent'};

  &:hover {
    background-color: ${(p) =>
    p.isSelected
      ? p.theme.color.selectedTableRowLime
      : p.theme.color.tinyGray};
  }
`;
