import Page from 'components/shared/Page';
import { observer } from 'mobx-react';
import React, { FC, useEffect, useState, useCallback, MouseEvent } from 'react';
import Grid from 'components/UI/Grid';
import styled from 'styled-components';
import { TabViewTabChangeEvent } from 'primereact/tabview';
import { useTranslation } from 'react-i18next';
import { useStores } from 'stores/hooks/hooks';
import { TabViewMode } from 'core/enums';
import Loading from 'components/UI/Loading/Loading';
import SystemsTable from './SystemsTable/SystemsTable';
import SystemSensors from './SystemSensors';
import MenuBar from './MenuBar';

const StyledGrid = styled(Grid)`
  width: 100%;
  justify-content: start;
  padding: 1rem;
  align-items: normal;
`;

const StyledMarginGrid = styled(Grid)``;

export const TreeContainer = styled.div`
  height: calc(100vh - 106px);
  margin: 1rem;
  margin-right: 0;
  overflow: hidden;
`;

export enum SystemTabs {
  Details = 0,
  Systems = 1,
}

const Systems: FC = observer(() => {
  const { t } = useTranslation('grower');
  const { systemsStore } = useStores();
  const [isLoading, setIsLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState<SystemTabs>(
    SystemTabs.Details,
  );

  const [plotsViewMode, setPlotsViewMode] = useState<TabViewMode>(
    TabViewMode.Table,
  );

  const onTabChange = useCallback((e: TabViewTabChangeEvent) => {
    setActiveIndex(e.index);
  }, []);

  const handlePlotsViewChange = useCallback(
    (_event: MouseEvent<HTMLElement>, value: TabViewMode) => {
      setPlotsViewMode((prev) => value ?? prev);
    },
    [],
  );

  const getSystems = useCallback(async () => {
    await systemsStore.getSystemTypes();
  }, [systemsStore]);

  useEffect(() => {
    getSystems();
    return () => {
      systemsStore.clearSystemTable();
    };
  }, [getSystems, systemsStore]);

  if (isLoading) {
    return (
      <Page>
        <Loading />
      </Page>
    );
  }

  return (
    <Page>
      <StyledGrid direction="column" flex="1">
        <StyledMarginGrid align="stretch">
          <MenuBar selectedTab={activeIndex} />
        </StyledMarginGrid>
        <SystemsTable />
        {systemsStore.selectedSystem && <SystemSensors />}
      </StyledGrid>
    </Page>
  );
});

export default Systems;
