import { setAuthorizationHeader } from 'Api/Api';
import axios from 'axios';
import { USER_STORAGE_KEY } from 'core/constants';
import i18next from 'i18next';
import {
  action,
  computed,
  makeAutoObservable,
  observable,
  runInAction,
} from 'mobx';
import { ErrorResponse } from 'models/shared';
import { promiseMaker } from 'utils/functions';
import veryLocalStorage from 'utils/vls';
// eslint-disable-next-line import/no-extraneous-dependencies
import { CoralogixRum } from '@coralogix/browser';
import api from '../Api/ApiMethods';
import { AccessTokenBlock, User } from '../models/restTypes';
import { RootStore } from './rootStore';
import packageJs from '../../package.json';

const {
  REACT_APP_CORALOGIX_RUM_KEY,
  REACT_APP_CORALOGIX_RUM_ENABLED,
  REACT_APP_CORALOGIX_RUM_RECORDING_ENABLED,
  REACT_APP_CORALOGIX_RUM_APPLICATION_NAME,
} = process.env;

export class UserStore {
  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: observable,
      accessTokenBlock: observable,
      user: observable,
      isRefreshingToken: observable,
      isAuthenticated: computed,
      userLogin: action,
      logOut: action,
      refreshUserToken: action,
    });

    this.rootStore = rootStore;
    const storage: AccessTokenBlock | false =
      veryLocalStorage.get<AccessTokenBlock>('accessTokenBlock');

    if (storage) {
      this.accessTokenBlock = storage;
      setAuthorizationHeader(storage.access_token);
    }

    const user = veryLocalStorage.get<User>(USER_STORAGE_KEY);
    if (user) {
      this.user = user;
    }

    this.initCoralogix();
  }

  rootStore?: RootStore = undefined;
  accessTokenBlock?: AccessTokenBlock | null = undefined;
  user?: User = undefined;
  isRefreshingToken: Promise<string> | null = null;

  get isAuthenticated() {
    return Boolean(this.accessTokenBlock);
  }

  async userLogin(email: string, password: string) {
    try {
      const res = await api.postLogin(email, password);
      if (res?.access_token_block && res?.user) {
        this.accessTokenBlock = res.access_token_block;
        veryLocalStorage.set('accessTokenBlock', res.access_token_block);

        if (this.accessTokenBlock) {
          setAuthorizationHeader(this.accessTokenBlock.access_token);
        }

        veryLocalStorage.set(USER_STORAGE_KEY, res.user);
      }

      if (res?.user) {
        this.user = res.user;
      }

      if (!res?.user && !res?.access_token_block) {
        if (res?.message) {
          this.rootStore?.snackBarStore.showToast({ detail: res.message });
        } else {
          this.rootStore?.snackBarStore.showToast({
            detail: i18next.t<string>('validation:user_not_found'),
          });
        }

        return false;
      }

      CoralogixRum.setUserContext({
        user_id: `${res?.user?.id ?? ''}${res?.user?.email ?? ''}`,
        user_name: `${res?.user?.firstName ?? ''} ${res?.user?.lastName ?? ''}`,
        user_email: res?.user?.email,
      });

      return true;
    } catch {
      return false;
    }
  }

  async refreshUserToken() {
    const { promise, res, rej } = promiseMaker();
    try {
      this.isRefreshingToken = promise;
      const refreshToken = this.accessTokenBlock?.refresh_token;
      if (refreshToken) {
        const refreshResponse = await api.postRefreshToken(refreshToken);
        if (refreshResponse.data.access_token_block) {
          runInAction(() => {
            this.accessTokenBlock = refreshResponse.data.access_token_block;
            setAuthorizationHeader(refreshResponse.data.access_token_block);

            veryLocalStorage.set(
              'accessTokenBlock',
              refreshResponse.data.access_token_block,
            );

            res(refreshResponse.data.access_token_block.access_token);
          });
        }
      }
    } catch (e) {
      rej(e);
    } finally {
      this.isRefreshingToken = null;
    }
  }

  async forgotPassword(email: string) {
    try {
      await api.postForgotPassword(email);
      this.rootStore?.snackBarStore.showToast({
        detail: i18next.t<string>('auth:forgot_email_success', { email }),
        severity: 'success',
        summary: i18next.t<string>('auth:forgot_email_success_summary'),
      });

      return true;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        this.rootStore?.snackBarStore.showToast({
          detail:
            (error.response?.data as ErrorResponse)?.message || error.message,
        });
      }

      if (error instanceof Error) {
        this.rootStore?.snackBarStore.showToast({ detail: error.message });
      }

      return false;
    }
  }

  async resetPassword(
    newPassword: string,
    email: string,
    passwordToken: string,
  ) {
    try {
      const res = await api.postResetPassword(
        newPassword,
        email,
        passwordToken,
      );

      if (res?.access_token_block) {
        this.accessTokenBlock = res.access_token_block;
        veryLocalStorage.set('accessTokenBlock', res.access_token_block);
      }

      if (res?.user) {
        this.user = res.user;
      }
    } catch (error) {
      if (error instanceof Error) {
        this.rootStore?.snackBarStore.showToast({ detail: error.message });
      }
    }
  }

  logOut() {
    this.accessTokenBlock = undefined;
    this.user = undefined;
    veryLocalStorage.clear();
  }

  initCoralogix() {
    if (REACT_APP_CORALOGIX_RUM_ENABLED) {
      CoralogixRum.init({
        public_key: REACT_APP_CORALOGIX_RUM_KEY,
        application:
          REACT_APP_CORALOGIX_RUM_APPLICATION_NAME || 'bot-undefined', // Provide a default value of an empty string if CORALOGIX_RUM_APPLICATION_NAME is undefined
        version: packageJs.version,
        coralogixDomain: 'EU1',
        sessionRecordingConfig: {
          enable: !!REACT_APP_CORALOGIX_RUM_RECORDING_ENABLED || false,
          autoStartSessionRecording: true,
          sessionRecordingSampleRate: 100,
        },
      });
    }

    if (this.user) {
      CoralogixRum.setUserContext({
        user_id: `${this?.user?.id ?? ''}${this?.user?.email ?? ''}`,
        user_name: `${this?.user?.firstName ?? ''} ${this?.user?.lastName ?? ''
          }`,
        user_email: this?.user?.email,
      });
    }
  }

  getSmsPhoneCode = async (phoneNumber: string) => {
    try {
      const res = await api.postAccessOtc(phoneNumber);
      return res?.data;
    } catch (error) {
      if (error instanceof Error) {
        this.rootStore?.snackBarStore.showToast({ detail: error.message });
      }
    }

    return false;
  };

  generateCoralogixError = (message: string) => {
    console.log('generate Coralogix Error: ', message);
    if (this.user) {
      CoralogixRum.setUserContext({
        user_id: `${this?.user?.id ?? ''}${this?.user?.email ?? ''}`,
        user_name: `${this?.user?.firstName ?? ''} ${this?.user?.lastName ?? ''
          }`,
        user_email: this?.user?.email,
      });
    }

    CoralogixRum.error(message, {
      userId: this?.user?.id,
      userName: `${this?.user?.firstName ?? ''} ${this?.user?.lastName ?? ''}`,
    });
  };

  verifyOtcLogin = async (requestId: string, otp: string) => {
    try {
      const res = await api.postVerifyOtc(requestId, otp);
      if (res?.access_token_block && res?.user) {
        this.accessTokenBlock = res.access_token_block;
        veryLocalStorage.set('accessTokenBlock', res.access_token_block);

        if (this.accessTokenBlock) {
          setAuthorizationHeader(this.accessTokenBlock.access_token);
        }

        veryLocalStorage.set(USER_STORAGE_KEY, res.user);
      }

      if (res?.user) {
        this.user = res.user;
      }

      if (!res?.user && !res?.access_token_block) {
        if (res?.message) {
          this.rootStore?.snackBarStore.showToast({ detail: res.message });
        } else {
          this.rootStore?.snackBarStore.showToast({
            detail: i18next.t<string>('validation:user_not_found'),
          });
        }

        return false;
      }

      CoralogixRum.setUserContext({
        user_id: `${res?.user?.id ?? ''}${res?.user?.email ?? ''}`,
        user_name: `${res?.user?.firstName ?? ''} ${res?.user?.lastName ?? ''}`,
        user_email: res?.user?.email,
      });

      return true;
    } catch (error) {
      if (error instanceof Error) {
        this.rootStore?.snackBarStore.showToast({ detail: error.message });
      }
    }

    return false;
  };
}
