import React, {
  FC,
  useState,
  useCallback,
  useEffect,
  ChangeEvent,
  useMemo,
} from 'react';
import { observer } from 'mobx-react';
import {
  DialogActions,
  DialogContent,
  Divider,
  TextField,
  TextFieldProps,
} from '@mui/material';
import styled from 'styled-components';
import Dialog from 'components/UI/Dialog';
import Button from 'components/UI/Button';
import Input from 'components/UI/Input';
import Grid from 'components/UI/Grid';
import Text from 'components/UI/Text';
import { theme } from 'components/UI';
import { useTranslation } from 'react-i18next';
import { useStores } from 'stores/hooks/hooks';
import Space from 'components/UI/Space';
import { ActionMode, DialogOnCloseReason } from 'models/shared';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import MultiSelect from 'components/UI/MultiSelect';
import { Coupon } from 'stores/couponsStore';
import { DropdownChangeEvent } from 'primereact/dropdown';
import Select from 'components/UI/Select';
import Loading from 'components/UI/Loading/Loading';

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  input {
    box-sizing: unset;
  }
`;

const StyledMultiSelect = styled(Select)``;

const NoWrappingText = styled(Text)`
  white-space: nowrap;
  min-width: 30%;
  max-width: 30%;
`;

interface GrowerManagementDialogProps {
  onHide: () => void;
  visible: boolean;
  title: string;
  mode: ActionMode;
}

const SystemsSearchDialog: FC<GrowerManagementDialogProps> = observer(
  ({ visible = false, onHide, title, mode }) => {
    const { t } = useTranslation('system');
    const { systemsStore } = useStores();
    const [systemEdit, setSystemEdit] = useState({
      systemType: '',
      systemSerial: '',
    });

    const systemTypes = useMemo(
      () =>
        systemsStore.systemTypes.data?.map((type) => ({
          label: type.SystemType.name,
          value: type.SystemType.type,
        })),
      [systemsStore.systemTypes.data],
    );

    const [growerNameEdit, setGrowerNameEdit] = useState('name');

    const [initialActionString, setInitialActionString] = useState('');
    useEffect(() => {
      if (initialActionString || growerNameEdit === '') {
        return;
      }

      setInitialActionString(growerNameEdit);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const closeDialog = useCallback(() => {
      setInitialActionString('');
      onHide();
    }, [onHide]);

    const handleCloseDialog = useCallback(
      (event: any, reason: DialogOnCloseReason) => {
        if (reason && reason === DialogOnCloseReason.BackDropClick) {
          return;
        }

        setGrowerNameEdit('');
        closeDialog();
      },
      [closeDialog],
    );

    const handleCloseButton = useCallback(() => {
      setGrowerNameEdit('');
      closeDialog();
    }, [closeDialog]);

    const handleChangeSelect = useCallback((event: DropdownChangeEvent) => {
      const { value } = event;
      setSystemEdit((prev) => ({ ...prev, systemType: value }));
    }, []);

    const handleChange = useCallback(
      (
        event: ChangeEvent<
          HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
        >,
      ) => {
        const { value } = event.target;
        setSystemEdit((prev) => ({ ...prev, systemSerial: value }));
      },
      [],
    );

    const handleSubmitClick = async () => {
      await systemsStore.getSystemByType(
        systemEdit.systemType,
        systemEdit.systemSerial,
      );

      closeDialog();
    };

    useEffect(() => {
      if (systemTypes?.length) {
        setSystemEdit((prev) => ({
          ...prev,
          systemType: systemTypes[0].value,
        }));
      }
    }, [systemTypes]);

    return (
      <Dialog
        open={visible}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="sm"
        dialogTitle={title}
        titleWeight="bold"
        disableEscapeKeyDown
      >
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Space height={1} />
            <Grid direction="column" gap="10px">
              {systemsStore?.allSystems?.loading ? (
                <Loading />
              ) : (
                <>
                  <Grid
                    direction="row"
                    gap="10px"
                    alignSelf="start"
                    grow
                    justify="start"
                  >
                    <NoWrappingText>{t('search_systems_type')}:</NoWrappingText>
                    <StyledMultiSelect
                      id="system-type"
                      options={systemTypes}
                      value={systemEdit.systemType}
                      onChange={handleChangeSelect}
                    />
                  </Grid>
                  <Grid direction="row" gap="10px" alignSelf="start" grow>
                    <NoWrappingText>
                      {t('search_systems_serial')}:
                    </NoWrappingText>
                    <Input
                      // required
                      label={t('search_systems_serial')}
                      id="search_systems_serial"
                      name="systemSerial"
                      value={systemEdit.systemSerial}
                      variant="outlined"
                      type="text"
                      fullWidth
                      onChange={handleChange}
                    />
                  </Grid>
                </>
              )}
            </Grid>
            <Space height={1} />
          </LocalizationProvider>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={handleSubmitClick} disabled={false}>
            <Text textcolor={theme.color.white} size="sm">
              {t('ok')}
            </Text>
          </Button>
          <Button onClick={handleCloseButton}>
            <Text textcolor={theme.color.white} size="sm">
              {t('cancel')}
            </Text>
          </Button>
        </DialogActions>
      </Dialog>
    );
  },
);

export default SystemsSearchDialog;
