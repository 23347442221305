import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import i18next from 'i18next';
import styled from 'styled-components';
import { Column, DataTable } from 'components/shared/DataTable/DataTable';
import { useTranslation } from 'react-i18next';
import Grid from 'components/UI/Grid';
import { useStores } from 'stores/hooks/hooks';
import { Paper } from '@mui/material';
import { FilterMatchMode } from 'primereact/api';
import { ActiveSensor, EditableSensor } from 'models/sensor';
import { ActionMode } from 'models/shared';
import { HALF_SCREEN_TABLE_BOTTOM_HEIGHT } from 'core/constants';
import { TableMultiSelection } from 'stores/types/types';
import {
  checkIsEditableSensorsValid,
  getPlotsMergedNames,
} from 'utils/plotSensorsUtils';
import { getDataTableMultiSelection } from 'utils/datatableUtils';
import {
  DataTableFilterMeta,
  DataTableRowClickEvent,
} from 'primereact/datatable';
import MultiselectDataTable, {
  MultiselectDataTableChangeEvent,
} from 'components/shared/MultiselectDataTable';

const Container = styled(Paper)`
  height: 100%;
  margin-top: 0.5rem;
`;

const DataTableContainer = styled(Grid)`
  overflow: scroll;
`;

const getPlotColumnBody = ({ plots }: ActiveSensor) => {
  return getPlotsMergedNames(plots);
};

const tableFilters: DataTableFilterMeta = {
  'sensor.sensorType.name': { value: '', matchMode: FilterMatchMode.CONTAINS },
  'sensor.displayName': { value: '', matchMode: FilterMatchMode.CONTAINS },
  'sensor.serial': { value: '', matchMode: FilterMatchMode.CONTAINS },
  'sensor.type': { value: '', matchMode: FilterMatchMode.CONTAINS },
  'sensor.latitude': { value: '', matchMode: FilterMatchMode.CONTAINS },
  'sensor.longitude': { value: '', matchMode: FilterMatchMode.CONTAINS },
  plotName: { value: '', matchMode: FilterMatchMode.CONTAINS },
  'params.id': { value: '', matchMode: FilterMatchMode.CONTAINS },
};

interface TableSensorRow extends ActiveSensor {
  sensorId: number;
  plotName: string;
}

const SystemSensorsTable: FC = observer(() => {
  const { t } = useTranslation('grower');
  const { systemsStore } = useStores();
  const { selectedSystemSensors } = systemsStore;
  const [selectedRows, setSelectedRows] = useState<
    TableMultiSelection<TableSensorRow>
  >({ selection: [], activeRow: null });

  const onMultiselectChange = useCallback(
    ({
      activeRow,
      selection,
    }: MultiselectDataTableChangeEvent<TableSensorRow>) => {
      setSelectedRows({ activeRow, selection });
    },
    [],
  );

  const onRowClick = useCallback(({ data }: DataTableRowClickEvent) => {
    setSelectedRows({
      selection: [data as TableSensorRow],
      activeRow: data as TableSensorRow,
    });
  }, []);

  const tableRows: TableSensorRow[] = useMemo(
    () =>
      selectedSystemSensors.data.map((growerSensor) => ({
        ...growerSensor,
        sensorId: growerSensor.sensor.id,
        plotName: getPlotsMergedNames(growerSensor.plots),
      })),
    [selectedSystemSensors.data],
  );

  useEffect(() => {
    systemsStore.getSensorTypes();
  }, [systemsStore]);

  return (
    <DataTableContainer display="block" overflow="scroll">
      <Container>
        <DataTable
          id="SystemSensorsTable"
          showGridlines
          resizableColumns
          value={tableRows}
          scrollable
          scrollHeight={HALF_SCREEN_TABLE_BOTTOM_HEIGHT}
          dataKey="sensorId"
          emptyMessage={t('empty_message')}
          stripedRows
          filters={tableFilters}
          filterDisplay="row"
          selection={selectedRows.selection}
          // activeRow={selectedRows.activeRow}
          onRowClick={onRowClick}
        >
          <Column
            field="sensor.sensorType.name"
            header={t('sensor')}
            sortable
            filter
          />
          <Column
            field="sensor.displayName"
            header={t('name')}
            sortable
            filter
          />
          <Column field="sensor.serial" header={t('serial')} sortable filter />
          <Column field="sensor.type" header={t('type')} sortable filter />
          <Column
            field="sensor.latitude"
            header={t('latitude')}
            sortable
            filter
          />
          <Column
            field="sensor.longitude"
            header={t('longitude')}
            sortable
            filter
          />
          <Column
            field="plotName"
            body={getPlotColumnBody}
            header={t('plot')}
            sortable
            filter
          />
          <Column
            field="sensor.params.id"
            header={t('orderId')}
            sortable
            filter
          />
        </DataTable>
      </Container>
    </DataTableContainer>
  );
});

export default SystemSensorsTable;
