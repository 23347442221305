import React, { FC, useCallback, useEffect, useState } from 'react';
import { TabPanel, TabView } from 'components/UI/TabView';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { TabViewTabChangeEvent } from 'primereact/tabview';

const StyledTabView = styled(TabView)`
  flex: unset;

  .p-tabview-panels {
    display: none;
  }
`;

export const VisualTabViewPanel = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
  background: #ffffff;
  padding: 1rem;
  z-index: 1;
  border: 0 none;
  color: #495057;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
`;

export type VisualTabItem = {
  header: string;
  component: JSX.Element;
  tabId: number;
};

type Props = {
  activeIndex: number;
  onTabChange: (tabData: { index: number; data: VisualTabItem }) => void;
  data: VisualTabItem[];
};

export const VisualTabView: FC<Props> = ({
  activeIndex,
  onTabChange,
  data,
}) => {
  const handleTabChange = useCallback(
    (e: TabViewTabChangeEvent) => {
      onTabChange({ index: e.index, data: data[e.index] });
    },
    [data, onTabChange],
  );

  useEffect(() => {
    onTabChange({ index: activeIndex, data: data[activeIndex] });
  }, [activeIndex, data, onTabChange]);

  return (
    <StyledTabView
      activeIndex={activeIndex}
      renderActiveOnly={false}
      onTabChange={handleTabChange}
    >
      {data.map((dataItem, index) => (
        <TabPanel header={dataItem.header} />
      ))}
    </StyledTabView>
  );
};

export default observer(VisualTabView);
