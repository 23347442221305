/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable react/no-unused-prop-types */
/* global google */
// eslint-disable
import { ObjectUtils } from 'primereact/utils';
import React from 'react';
import { useMountEffect, useUpdateEffect } from 'stores/hooks/hooks';

interface GMapEventParams {
  originalEvent: React.SyntheticEvent;
  overlay: any;
  map: any;
}

export interface GMapProps
  extends Omit<
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >,
    'ref'
  > {
  options?: object;
  overlays?: any[];
  onMapReady?(map: any): void;
  onMapClick?(event: React.SyntheticEvent): void;
  onMapDragEnd?(): void;
  onZoomChanged?(): void;
  onOverlayDragStart?(event: React.SyntheticEvent): void;
  onOverlayDrag?(event: React.SyntheticEvent): void;
  onOverlayDragEnd?(event: React.SyntheticEvent): void;
  onOverlayClick?(e: GMapEventParams): void;
  children?: React.ReactNode;
}

export declare class GMapClass extends React.Component<GMapProps, any> {
  public getMap(): any;
  public getElement(): HTMLDivElement;
}

export const GMap: React.FC<GMapProps> = React.forwardRef((props, ref) => {
  const elementRef = React.useRef(null);
  const map = React.useRef(null);
  const prevOverlays = React.useRef(null);

  const initMap = () => {
    map.current = new google.maps.Map(elementRef.current, props.options);

    if (props.onMapReady) {
      props.onMapReady({
        map: map.current,
      });
    }

    initOverlays(props.overlays);

    bindMapEvent('click', props.onMapClick);
    bindMapEvent('dragend', props.onMapDragEnd);
    bindMapEvent('zoom_changed', props.onZoomChanged);
  };

  const initOverlays = (overlays: any) => {
    if (overlays) {
      overlays.forEach((overlay: any) => {
        overlay.setMap(map.current);
        bindOverlayEvents(overlay);
      });

      prevOverlays.current = overlays;
    }
  };

  const bindOverlayEvents = (overlay: any) => {
    overlay.addListener('click', (event: any) => {
      if (props.onOverlayClick) {
        props.onOverlayClick({
          originalEvent: event,
          overlay,
          map: map.current,
        });
      }
    });

    if (overlay.getDraggable()) {
      bindDragEvents(overlay);
    }
  };

  const bindDragEvents = (overlay: any) => {
    bindDragEvent(overlay, 'dragstart', props.onOverlayDragStart);
    bindDragEvent(overlay, 'drag', props.onOverlayDrag);
    bindDragEvent(overlay, 'dragend', props.onOverlayDragEnd);
  };

  const bindMapEvent = (eventName: any, callback: any) => {
    // @ts-ignore
    map.current.addListener(eventName, (event: any) => {
      callback && callback(event);
    });
  };

  const bindDragEvent = (overlay: any, eventName: any, callback: any) => {
    overlay.addListener(eventName, (event: any) => {
      if (callback) {
        callback({
          originalEvent: event,
          overlay,
          map: map.current,
        });
      }
    });
  };

  const removeOverlays = (overlays: any) => {
    if (overlays) {
      overlays.forEach((overlay: any) => {
        overlay.setMap(null);
        unbindOverlayEvents(overlay);
      });
    }
  };

  const unbindOverlayEvents = (overlay: any) => {
    google.maps.event.clearListeners(overlay, 'click');

    if (overlay.getDraggable()) {
      google.maps.event.clearListeners(overlay, 'dragstart');
      google.maps.event.clearListeners(overlay, 'drag');
      google.maps.event.clearListeners(overlay, 'dragend');
    }
  };

  const getMap = () => {
    return map.current;
  };

  useMountEffect(() => {
    initMap();
  });

  useUpdateEffect(() => {
    initOverlays(props.overlays);

    return () => {
      removeOverlays(prevOverlays.current);
    };
  });

  React.useImperativeHandle(ref, () => ({
    props,
    getMap,
    getElement: () => elementRef.current,
  }));

  const otherProps = ObjectUtils.findDiffKeys(props, GMap.defaultProps);

  return (
    <div
      ref={elementRef}
      style={props.style}
      className={props.className}
      {...otherProps}
    />
  );
});

GMap.displayName = 'GMap';
GMap.defaultProps = {
  __TYPE: 'GMap',
  options: null,
  overlays: null,
  style: null,
  className: null,
  onMapReady: null,
  onMapClick: null,
  onMapDragEnd: null,
  onZoomChanged: null,
  onOverlayDragStart: null,
  onOverlayDrag: null,
  onOverlayDragEnd: null,
  onOverlayClick: null,
};
