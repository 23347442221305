import ReactJson from 'react-json-view';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import Grid from 'components/UI/Grid';
import { useStores } from 'stores/hooks/hooks';
import Text from 'components/UI/Text';
import { useTranslation } from 'react-i18next';

const Container = styled(Grid)`
  .react-json-view {
    max-height: 35vh;
  }
`;

const WrapGrid = styled(Grid)`
  flex-wrap: wrap;
`;

const SystemDetails: FC = observer(() => {
  const { systemsStore } = useStores();
  const { selectedSystem } = systemsStore;
  const { t } = useTranslation('grower');

  return (
    <Container direction="row" justify="start">
      {!!selectedSystem && (
        <WrapGrid
          align="start"
          gap="0.5rem"
          justify="start"
          display="grid"
          grid-template-columns="auto auto"
        >
          <Text>{t('grower_id')}:</Text>
          <Text weight="bold">&nbsp;{selectedSystem.growerId}&nbsp;</Text>
          <Text>{t('grower_name')}:</Text>
          <Text weight="bold">
            &nbsp;{systemsStore.allSystems.data.grower?.name || ''}&nbsp;
          </Text>
        </WrapGrid>
      )}
    </Container>
  );
});

export default SystemDetails;
