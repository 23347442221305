import api from 'Api/ApiMethods';
import { makeAutoObservable } from 'mobx';
import { TreeExpandedKeysType } from 'primereact/tree';
import i18next from 'i18next';
import { CompanyRole, UserGrowerRole, UserGrower } from 'models/user';
import {
  GrowerType,
  ResellerUsers,
  TreeGrower,
  ResellerTreeNode,
  CustomTreeNode,
  ResellerTreeMode,
  GrowerSystem,
} from 'models/grower';
import {
  TREE_NODE_HIDDEN_CLASS_NAME,
  TREE_NODE_INACTIVE_CLASS_NAME,
} from 'core/constants';
import {
  getAttachedSensorsRecord,
  getSensorCreatePayload,
} from 'utils/plotSensorsUtils';
import { getSafeArray } from 'utils/arrayUtils';
import { getDataTableMultiSelection } from 'utils/datatableUtils';
import { UpdatedStatus } from 'models/shared';
import { AlertVariant } from 'models/alert';
import { ActiveSensor, EditableSensor, Sensor } from 'models/sensor';
import { getAxiosErrorMessage } from 'utils/httpErrorsHelpers';
import { PlotExcel, PlotStatus, WebPlot } from 'models/plot';
import veryLocalStorage from 'utils/vls';
import { displayFileOrDownload } from 'utils/fileHelpers';
import { RootStore } from './rootStore';
import { LoadableField, TableMultiSelection } from './types/types';

export class ResellersStore {
  rootStore: RootStore;
  isInactiveNodesVisible = false;
  isUpdatingGrowerStatus = false;
  expandedTreeNodes: TreeExpandedKeysType = {};
  expandedResellerTreeNodes: TreeExpandedKeysType = {};
  selectedTreeNode: CustomTreeNode<ResellerTreeNode> | null = null;
  selectedResellerTreeNode: CustomTreeNode<ResellerTreeNode> | null = null;
  resellerTree: ResellerTreeNode[] = [];
  resellerTreeLoading = false;
  growerTableMultiSelection: TableMultiSelection<TreeGrower> = {
    activeRow: null,
    selection: [],
  };

  globalFilter = '';
  selectedRowsUserNotification: TreeGrower[] = [];
  selectedReseller: string[] = [];
  isEntityHaveChildren = true;
  growerAction: TreeGrower = {} as TreeGrower;
  alertsMapVariant: AlertVariant = AlertVariant.Technical;
  // Users
  resellerUsers: ResellerUsers[] = [];
  isRemovingGrowerSystem = false;
  growerSystems: LoadableField<GrowerSystem[]> = {
    data: [],
    loading: false,
  };

  growerSystemSensors: LoadableField<ActiveSensor[]> = {
    data: [],
    loading: false,
  };

  growerSensors: LoadableField<Sensor[]> = {
    data: [],
    loading: false,
  };

  plotSensors: LoadableField<Sensor[]> = {
    data: [],
    loading: false,
  };

  plotSystems: LoadableField<GrowerSystem[]> = {
    data: [],
    loading: false,
  };

  growerUnusedSensors: LoadableField<Sensor[]> = {
    data: [],
    loading: false,
  };

  growerUsersTableMultiSelection: TableMultiSelection<ResellerUsers> = {
    activeRow: null,
    selection: [],
  };

  selectedRowsSystems: GrowerSystem[] = [];
  companyRoles: CompanyRole[] = [];
  // Plots
  resellerPlots: WebPlot[] = [];
  selectedRowsPlots: WebPlot[] = [];
  resellerPlotsLoading = false;
  plotUploadExcel: PlotExcel[] = [];
  invalidPlotsFileBlob: Blob = new Blob();
  invalidPlotsFileName = '';

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  get selectedRows(): TreeGrower[] {
    return getDataTableMultiSelection(this.growerTableMultiSelection);
  }

  get selectedRowsUsers(): ResellerUsers[] {
    return getDataTableMultiSelection(this.growerUsersTableMultiSelection);
  }

  get growers() {
    if (!this.selectedTreeNode) {
      return [];
    }

    return this.selectedTreeNode?.data?.growers || [];
  }

  get resellers(): ResellerTreeNode[] {
    const childNodes = this.selectedTreeNode?.children?.map(({ data }) => data);

    return (childNodes ?? []) as ResellerTreeNode[];
  }

  get selectedGrowerType(): GrowerType {
    return this.selectedTreeNode?.data?.growerType as GrowerType;
  }

  get selectedGrowerId(): number {
    return Number(this.selectedTreeNode?.data?.id);
  }

  get selectedGrowerSystemId(): number | null {
    return this.selectedRowsSystems[0]?.id;
  }

  get isLowestTreeLevelSelected(): boolean {
    const selectedNode = this.selectedTreeNode?.data;
    return !!(
      selectedNode?.growerType === GrowerType.Grower ||
      selectedNode?.growers?.every(
        (child) => child.growerType === GrowerType.Grower,
      )
    );
  }

  get isTreeLevelIncludingGrower(): boolean {
    const selectedNode = this.selectedTreeNode?.data;
    return !!(
      selectedNode?.growerType === GrowerType.Grower ||
      selectedNode?.growers?.some(
        (child) => child.growerType === GrowerType.Grower,
      )
    );
  }

  get selectedTreeNodeGrowerIds(): number[] {
    const selectedNode = this.selectedTreeNode?.data;
    if (selectedNode?.growerType === GrowerType.Grower) {
      return [selectedNode.id];
    }

    if (
      selectedNode?.growerType === GrowerType.Reseller &&
      this.isLowestTreeLevelSelected
    ) {
      return selectedNode.growers?.map(({ id }) => id) ?? [];
    }

    return [];
  }

  // growerIds if at least one grower is included in the current depth or is lowest depth reseller
  get selectedTreeNodeCurrentDepthGrowerIds(): number[] {
    const selectedNode = this.selectedTreeNode?.data;
    if (selectedNode?.growerType === GrowerType.Grower) {
      return [selectedNode.id];
    }

    if (
      selectedNode?.growerType === GrowerType.Reseller &&
      (this.isTreeLevelIncludingGrower || this.isLowestTreeLevelSelected)
    ) {
      return selectedNode.growers?.map(({ id }) => id) ?? [];
    }

    return [];
  }

  get selectedGrowersForActivation(): ResellerTreeNode[] {
    if (this.selectedRows.length) {
      return this.selectedRows;
    }

    if (this.selectedTreeNode?.data) {
      return [this.selectedTreeNode?.data] as ResellerTreeNode[];
    }

    return [];
  }

  setAlertsMapVariant = (variant: AlertVariant) => {
    this.alertsMapVariant = variant;
  };

  setExpandedTreeNodes = (
    obj: TreeExpandedKeysType,
    mode: ResellerTreeMode,
  ) => {
    if (mode === ResellerTreeMode.All) {
      this.expandedTreeNodes = obj;
    } else {
      this.expandedResellerTreeNodes = obj;
    }
  };

  addExpandedTreeNodes = (key: string, mode: ResellerTreeMode) => {
    if (mode === ResellerTreeMode.All) {
      this.expandedTreeNodes[key] = true;
    } else {
      this.expandedResellerTreeNodes[key] = true;
    }
  };

  removeExpandedTreeNodes = (key: string) => {
    delete this.expandedTreeNodes[key];
  };

  resetTreeNodeData = (): void => {
    this.growerSystemSensors.data = [];
    this.growerSensors.data = [];
    this.plotSensors.data = [];
    this.resellerPlots = [];
    this.resellerUsers = [];
    this.growerTableMultiSelection = { selection: [], activeRow: null };
    this.growerUsersTableMultiSelection = { selection: [], activeRow: null };
    this.selectedRowsPlots = [];
    this.selectedRowsSystems = [];
    this.growerSystems = { data: [], loading: false };
  };

  setSelectedTreeNode = (
    node: CustomTreeNode<ResellerTreeNode> | null,
    mode: ResellerTreeMode,
  ) => {
    if (mode === ResellerTreeMode.All) {
      this.selectedTreeNode = node;
    } else {
      this.selectedResellerTreeNode = node;
    }

    this.resetTreeNodeData();
    if (this.selectedTreeNode) {
      this.getResellerUsers(this.selectedTreeNode.data?.id || 0);
      if (
        this.selectedTreeNode.data?.growerType === GrowerType.Grower &&
        this.selectedTreeNode.data?.isActive
      ) {
        this.getResellerPlots(this.selectedTreeNode.data?.id, false);
      }
    }
  };

  getResellerUsers = async (growerId: number) => {
    try {
      const res = await api.getResellerUsers(growerId);
      if (res) {
        this.resellerUsers = res.paginatedCollection;
      }
    } catch (error) {
      this.errorToast(`Something get wrong, ${error}`);
    }
  };

  getGrowerSystems = async (growerId: number) => {
    this.growerSystems.loading = true;

    try {
      const response = await api.getGrowerSystems(growerId);
      this.growerSystems.data = Array.isArray(response) ? response : [];
    } catch {
      this.rootStore?.snackBarStore.showToast({
        detail: i18next.t('errors:something_went_wrong'),
      });
    } finally {
      this.growerSystems.loading = false;
    }
  };

  removeGrowerSystem = async (systemId: number): Promise<void> => {
    this.isRemovingGrowerSystem = true;

    try {
      await api.deleteSystem(systemId);
      this.selectedRowsSystems = [];
      this.growerSystems.data = this.growerSystems.data.filter(
        (system) => system.id !== systemId,
      );

      this.successToast(i18next.t('grower:remove_system_success_message'));
    } catch (error) {
      this.errorToast(this.getErrorMessage(error));
    } finally {
      this.isRemovingGrowerSystem = false;
    }
  };

  getGrowerSystemSensors = async (
    growerId: number,
    systemId: number,
  ): Promise<void> => {
    this.growerSystemSensors.loading = true;
    try {
      const response = await api.getSystemActiveSensors(growerId, systemId);
      this.growerSystemSensors.data = getSafeArray(response);
      veryLocalStorage.set('defaultSensors', getSafeArray(response));
    } catch (error) {
      this.errorToast(this.getErrorMessage(error));
    } finally {
      this.growerSystemSensors.loading = false;
    }
  };

  getPlotSensors = async (plotId: number): Promise<void> => {
    this.plotSensors.loading = true;
    try {
      const response = await api.getPlotSensors(plotId);
      this.plotSensors.data = getSafeArray(response?.sensors);
    } catch (error) {
      this.errorToast(this.getErrorMessage(error));
    } finally {
      this.plotSensors.loading = false;
    }
  };

  getGrowerSensors = async (growerId: number) => {
    this.growerSensors.loading = true;
    try {
      const response = await api.getGrowerSensors(growerId);
      this.growerSensors.data = getSafeArray(response?.sensors);
    } catch (error) {
      this.errorToast(this.getErrorMessage(error));
    } finally {
      this.growerSensors.loading = false;
    }
  };

  getAvailablePlotSensors = async (plotId: number): Promise<void> => {
    this.plotSensors.loading = true;
    this.growerSensors.loading = true;
    try {
      const [plotSensors, growerSensors] = await Promise.all([
        api.getPlotSensors(plotId),
        api.getGrowerSensors(this.selectedGrowerId),
      ]);

      this.plotSensors.data = getSafeArray(plotSensors?.sensors);
      this.growerSensors.data = getSafeArray(growerSensors?.sensors);
      this.plotSystems.data = Object.values(plotSensors?.systems || {});
    } catch (error) {
      this.errorToast(this.getErrorMessage(error));
    } finally {
      this.plotSensors.loading = false;
      this.growerSensors.loading = false;
    }
  };

  getGrowerUnusedSensors = async (
    growerId: number,
    systemId: number,
  ): Promise<void> => {
    this.growerUnusedSensors.loading = true;

    try {
      const response = await api.getSystemSensors(growerId, systemId);
      const sensors = Array.isArray(response)
        ? await this.rootStore.systemsStore.setSensorsDefaultGroup(response)
        : [];

      this.growerUnusedSensors.data = sensors;
      this.growerUnusedSensors.error = null;
    } catch (error) {
      this.growerUnusedSensors.error = error;
      this.errorToast(this.getErrorMessage(error));
      throw error;
    } finally {
      this.growerUnusedSensors.loading = false;
    }
  };

  attachSensorsToPlot = async (
    plotId: number,
    sensors: Sensor[],
  ): Promise<void> => {
    try {
      const systemId = this.selectedGrowerSystemId;
      const modifiedSensors = sensors.map((sensor) => ({
        ...this.generateSensors(sensor),
      }));

      await api.putAttachMultiplePlotToSensor(plotId, modifiedSensors);
      this.plotSensors.data = [...this.plotSensors.data, ...sensors];

      if (systemId) {
        this.getGrowerSystemSensors(this.selectedGrowerId, systemId);
      }
    } catch (error) {
      this.errorToast(this.getErrorMessage(error));
      throw error;
    }
  };

  detachSensorsFromPlot = async (
    plotId: number,
    sensors: Sensor[],
  ): Promise<void> => {
    try {
      const systemId = this.selectedGrowerSystemId;
      const deleteSensorsIds = sensors.map((sensor) => sensor.id);
      await Promise.all(
        deleteSensorsIds.map((sensorId) =>
          api.deleteSensorsFromPlot(plotId, sensorId),
        ),
      );

      this.plotSensors.data = this.plotSensors.data.filter(
        (sensor) => !deleteSensorsIds.includes(sensor.id),
      );

      if (systemId) {
        this.getGrowerSystemSensors(this.selectedGrowerId, systemId);
      }
    } catch (error) {
      this.errorToast(this.getErrorMessage(error));
      throw error;
    }
  };

  private attachEditedSensorsToPlot = async (
    sensors: EditableSensor[],
  ): Promise<void> => {
    try {
      const modifiedSensors = sensors.map((sensor) => {
        return {
          ...sensor,
          sensor: { ...this.generateSensors(sensor.sensor) },
        };
      });

      await Promise.all(
        Object.entries(getAttachedSensorsRecord(modifiedSensors)).map(
          ([plotId, plotSensors]) =>
            api.putAttachMultiplePlotToSensor(Number(plotId), plotSensors),
        ),
      );
    } catch (error) {
      this.errorToast(this.getErrorMessage(error));
      throw error;
    }
  };

  updateGrowerSensors = async (growerId: number, sensors: EditableSensor[]) => {
    try {
      await Promise.all(
        sensors.map(({ sensor }) => {
          return api.putUpdateSensor({ ...this.generateSensors(sensor) });
        }),
      );

      const updatedSensors: Record<string, ActiveSensor> = sensors.reduce(
        (acc, { sensor, details }) => ({
          ...acc,
          [sensor.id]: {
            sensor,
            plots: details.plots,
          },
        }),
        {},
      );

      this.growerSystemSensors.data = this.growerSystemSensors.data.map(
        (growerSensor) =>
          updatedSensors[growerSensor.sensor.id] ?? growerSensor,
      );

      this.successToast(i18next.t('grower:sensors_updated_message'));
    } catch (error) {
      this.errorToast(this.getErrorMessage(error));
    }
  };

  removeGrowerSensors = async (
    growerId: number,
    systemId: number,
    sensors: Sensor[],
  ) => {
    try {
      await Promise.all(
        sensors.map((sensor) =>
          api.deleteSensor(growerId, systemId, sensor.id),
        ),
      );

      const deletedIds = new Set(sensors.map((sensor) => sensor.id));
      this.growerSystemSensors.data = this.growerSystemSensors.data.filter(
        ({ sensor }) => !deletedIds.has(sensor.id),
      );

      this.successToast(
        i18next.t('toast:success_message_multiple', {
          action: 'remove',
          entity: 'sensor',
        }),
      );
    } catch (error) {
      this.errorToast(this.getErrorMessage(error));
    }
  };

  addGrowerSensors = async (
    growerId: number,
    systemId: number,
    sensors: EditableSensor[],
  ): Promise<void> => {
    try {
      if (!this.rootStore.systemsStore.sensorTypes.data.length) {
        await this.rootStore.systemsStore.getSensorTypes();
      }

      const createSensorsPayload = sensors.map(({ sensor }) =>
        getSensorCreatePayload(
          sensor,
          this.rootStore.systemsStore.sensorTypes.data,
        ),
      );

      const response = await api.postBatchCreateSensors(
        growerId,
        systemId,
        createSensorsPayload,
      );

      const createdSensors: ActiveSensor[] =
        response.created?.map((sensorData) => {
          const attachedPlots =
            sensors.find(
              ({ sensor }) => sensorData.object.serial === sensor.serial,
            )?.details?.plots ?? [];

          return {
            sensor: sensorData.object,
            plots: attachedPlots,
          };
        }) ?? [];

      const editedSensors: EditableSensor[] = sensors.map(
        ({ sensor, details }) => {
          const matchedSensor = createdSensors.find(
            (createdSensor) => sensor.serial === createdSensor.sensor.serial,
          );

          return {
            sensor: matchedSensor?.sensor,
            details,
          } as EditableSensor;
        },
      );

      this.growerSystemSensors.data = [
        ...this.growerSystemSensors.data,
        ...createdSensors,
      ];

      response.failed?.forEach((sensor) => {
        this.errorToast(
          sensor.attributes?.error_key ??
          i18next.t('system:sensor_create_error_message', {
            serial: sensor.object.serial,
          }),
        );
      });

      await this.attachEditedSensorsToPlot(editedSensors);
    } catch (error) {
      this.errorToast(this.getErrorMessage(error));
      throw error;
    }
  };

  setResellerPlotsLoading = (bool: boolean) => {
    this.resellerPlotsLoading = bool;
  };

  getResellerPlots = async (resellerId: number, historic: boolean) => {
    try {
      this.setResellerPlotsLoading(true);
      const res = await api.getResellerPlots(resellerId, historic);
      if (res) {
        this.resellerPlots = res;
      }
    } catch (error) {
      this.errorToast(this.getErrorMessage(error));
    } finally {
      this.setResellerPlotsLoading(false);
    }
  };

  get selectedTreeNodeKey() {
    return this.selectedTreeNode?.key || null;
  }

  get selectedResellerTreeNodeKey() {
    return this.selectedResellerTreeNode?.key || null;
  }

  get dataTree() {
    return this.resellerTree
      .filter((el) => el.resellerId === -1)
      .map((el, i) =>
        this.generateTreeNodes(
          el,
          this.resellerTree,
          this.expandedTreeNodes,
          i.toString(),
        ),
      );
  }

  get dataResellerTree() {
    return this.resellerTree
      .filter((el) => el.resellerId === -1)
      .map((el, i) =>
        this.generateResellerTreeNodes(
          el,
          this.resellerTree,
          this.expandedResellerTreeNodes,
          i.toString(),
        ),
      );
  }

  get tableValues() {
    return [...this.resellers];
  }

  setResellerTree = (resellerTree: ResellerTreeNode[]) => {
    this.resellerTree = resellerTree;
  };

  setSelectedRows = (selection: TableMultiSelection<TreeGrower>) => {
    this.growerTableMultiSelection = selection;
    this.isEntityHaveChildren = this.selectedRows.some(
      (row) => !!row.numOfGrowers,
    );
  };

  setSelectedRowsUsers = (selection: TableMultiSelection<ResellerUsers>) => {
    this.growerUsersTableMultiSelection = selection;
  };

  setSelectedRowsSystems = (data: GrowerSystem[]) => {
    this.selectedRowsSystems = data;
  };

  setSelectedRowsPlots = (selectedRows: WebPlot[]) => {
    this.selectedRowsPlots = selectedRows;
  };

  setGlobalFilter = (str: string) => {
    this.globalFilter = str;
  };

  setSelectedRowsUserNotification = (selectedRows: TreeGrower[]) => {
    this.selectedRowsUserNotification = selectedRows;
  };

  setSelectedReseller = (resellers: string[]) => {
    this.selectedReseller = resellers;
  };

  get resellersOptions() {
    return Array.from(new Set(this.growers.map((el) => el.resellerName))).map(
      (item) => ({
        label: item,
        value: item,
      }),
    );
  }

  removeSelectedGrowers = async () => {
    try {
      const growersToDelete = this.selectedRows.map((grower) => {
        grower.isActive = false;
        return grower;
      });

      const res =
        (await api.deleteGrowers(growersToDelete as TreeGrower[])) || [];

      const deleteGrowersStatus = res.filter((el: UpdatedStatus) => el.updated);
      const growerIds = deleteGrowersStatus.map(
        (grower: UpdatedStatus) => grower.id,
      );

      if (this.selectedRows[0].growerType === GrowerType.Grower) {
        this.resellerTree.forEach((el) => {
          if (el.id === this.selectedTreeNode?.data?.id) {
            const growers = el.growers?.filter(
              (grower) => !growerIds.includes(grower.id),
            );

            el.growers = growers ? [...growers] : [];
          }
        });
      } else {
        const resellers = this.resellerTree.filter(
          (reseller) => reseller.id !== this.selectedRows[0].id,
        );

        this.resellerTree = [...resellers];
      }

      if (this.selectedTreeNode) {
        const childrenGrowers =
          this.selectedTreeNode.children?.filter(
            (el) => !growerIds.includes(el?.data?.id || -5),
          ) || [];

        this.selectedTreeNode.children = [...childrenGrowers];
      }

      this.setSelectedRows({ selection: [], activeRow: null });
      const notDeleteGrowersStatus = res.filter(
        (el: UpdatedStatus) => !el.updated,
      );

      if (notDeleteGrowersStatus.length) {
        this.rootStore?.snackBarStore.showToast({
          detail: `Grower doesn't deleted: ${notDeleteGrowersStatus.map(
            (el: UpdatedStatus) => el.id,
          )}`,
        });
      } else {
        this.successToast(`Grower delete successfully`);
      }
    } catch (error) {
      this.errorToast(`Something get wrong, ${error}`);
    }
  };

  setTreeDisplayInactiveValue = (value: boolean): void => {
    this.isInactiveNodesVisible = value;
    if (!this.selectedTreeNode?.data?.isActive) {
      this.selectedTreeNode = null;
    }
  };

  private updateTreeNodeChildrenStatus = (
    growers: ResellerTreeNode[],
    isActive: boolean,
  ) => {
    if (!this.selectedTreeNode?.children.length) {
      return;
    }

    const updatedChildrenIds = new Set(growers.map((grower) => grower.id));
    this.selectedTreeNode.children = this.selectedTreeNode.children.map(
      (child) =>
        updatedChildrenIds.has(child.data?.id as number)
          ? { ...child, data: { ...child.data, isActive } }
          : child,
    ) as CustomTreeNode<ResellerTreeNode>[];
  };

  updateGrowersStatus = async (
    growers: ResellerTreeNode[],
    isActive: boolean,
  ) => {
    try {
      this.isUpdatingGrowerStatus = true;
      const isTreeNodeSelected =
        this.selectedTreeNode?.data?.id === growers[0]?.id;

      await Promise.all(
        growers.map((grower) => api.putUpdateGrowerStatus(grower.id, isActive)),
      );

      this.resellerTree = await api.getAllGrowers();
      if (!isTreeNodeSelected) {
        this.updateTreeNodeChildrenStatus(growers, isActive);
      } else if (
        isTreeNodeSelected &&
        !this.isInactiveNodesVisible &&
        !isActive
      ) {
        this.selectedTreeNode = null;
      } else if (isTreeNodeSelected && this.selectedTreeNode?.data) {
        this.selectedTreeNode.data = {
          ...this.selectedTreeNode.data,
          isActive,
        };
      }

      this.setSelectedRows({ selection: [], activeRow: null });
    } catch (error) {
      this.errorToast(this.getErrorMessage(error));
    } finally {
      this.isUpdatingGrowerStatus = false;
    }
  };

  async initData() {
    this.resellerTreeLoading = true;

    try {
      this.companyRoles = await api.getAllCompanyRoles();
      const resellerTree = await api.getAllGrowers();
      if (resellerTree) {
        this.setResellerTree(resellerTree);
        const foundInitialNode = this.dataTree.find(
          (el) => el.data?.resellerId === -1,
        );

        if (foundInitialNode) {
          this.setSelectedTreeNode(foundInitialNode, ResellerTreeMode.All);
          this.addExpandedTreeNodes(foundInitialNode.key, ResellerTreeMode.All);
        }
      }
    } catch (error) {
      this.errorToast(`Something get wrong, ${error}`);
    } finally {
      this.resellerTreeLoading = false;
    }
  }

  private getTreeNodeClassName = (node: ResellerTreeNode): string => {
    if (!node.isActive) {
      return this.isInactiveNodesVisible
        ? TREE_NODE_INACTIVE_CLASS_NAME
        : TREE_NODE_HIDDEN_CLASS_NAME;
    }

    return '';
  };

  generateTreeNodes = (
    node: ResellerTreeNode,
    allResellers: ResellerTreeNode[],
    expandedNodes: TreeExpandedKeysType,
    id = '0',
  ): CustomTreeNode<ResellerTreeNode> => {
    const resellersChildren = allResellers
      .filter((el) => el.resellerId === node.id)
      .map((el, i) =>
        this.generateTreeNodes(el, allResellers, expandedNodes, `${id}-${i}`),
      );

    const growersChildren =
      node.growers
        ?.filter((grower) => grower.growerType === GrowerType.Grower)
        .map((grower) => ({
          key: grower.id,
          label: grower.name,
          data: grower,
          icon: null,
          className: this.getTreeNodeClassName(grower),
          children: null,
        })) || [];

    return {
      key: id,
      label: node.name,
      data: node,
      icon: null,
      className: this.getTreeNodeClassName(node),
      children: [...resellersChildren, ...growersChildren],
    } as CustomTreeNode<ResellerTreeNode>;
  };

  generateResellerTreeNodes = (
    node: ResellerTreeNode,
    allResellers: ResellerTreeNode[],
    expandedNodes: TreeExpandedKeysType,
    id = '0',
  ): CustomTreeNode<ResellerTreeNode> => {
    return {
      key: id,
      label: node.name,
      data: node,
      icon: expandedNodes[id]
        ? 'pi pi-fw pi-folder-open'
        : 'pi pi-fw pi-folder',
      className: this.getTreeNodeClassName(node),
      children: allResellers
        .filter((el) => el.resellerId === node.id)
        .map((el, i) =>
          this.generateResellerTreeNodes(
            el,
            allResellers,
            expandedNodes,
            `${id}-${i}`,
          ),
        ),
    } as CustomTreeNode<ResellerTreeNode>;
  };

  moveGrowerToReseller = async () => {
    const growerIds = this.selectedRows.map((grower) => grower.id);
    await api.putMoveGrowerToReseller(
      this.selectedResellerTreeNode?.data?.id,
      growerIds,
    );

    if (this.selectedTreeNode && this.selectedTreeNode.children) {
      const childrenGrowers =
        this.selectedTreeNode?.children?.filter(
          (el) => !growerIds.includes(el?.data?.id || -5),
        ) || [];

      this.selectedTreeNode.children = childrenGrowers;
    }

    let growers: TreeGrower[] = [];
    this.resellerTree.forEach((reseller) => {
      if (reseller.id === this.selectedTreeNode?.data?.id) {
        growers =
          reseller.growers?.filter((grower) => growerIds.includes(grower.id)) ||
          [];

        reseller.growers =
          reseller.growers?.filter(
            (grower) => !growerIds.includes(grower.id),
          ) || [];
      }
    });

    this.resellerTree.forEach((reseller) => {
      if (reseller.id === this.selectedResellerTreeNode?.data?.id) {
        reseller.growers = reseller.growers
          ? [...reseller.growers, ...growers]
          : growers;
      }
    });

    this.successToast(`Grower moved successfully`);
  };

  editResellerUserRole = async (role: UserGrowerRole) => {
    const usersIds = this.selectedRowsUsers.map((user) => user.id);
    try {
      await Promise.all(
        usersIds.map((userId) =>
          api.putAssignUserGrowers(userId, role.id, [this.selectedGrowerId]),
        ),
      );

      this.resellerUsers.forEach((user) => {
        if (usersIds.includes(user.id)) {
          user.userRole = role.name;
        }
      });

      this.setSelectedRowsUsers({ selection: [], activeRow: null });
      this.successToast(`User role updated successfully`);
    } catch {
      this.errorToast(`Can't edit roles for growers ${usersIds.toString()}`);
    }
  };

  assignUsersToGrower = async (
    growerId: number,
    role: UserGrowerRole,
    users: ResellerUsers[],
  ) => {
    const assignedUsers: ResellerUsers[] = [];

    // eslint-disable-next-line no-restricted-syntax
    for await (const user of users) {
      const messageData = {
        firstName: user.firstName,
        lastName: user.lastName,
        roleName: role.name,
      };

      try {
        await api.putAssignUserGrowers(user.id, role.id, [growerId]);
        assignedUsers.push({
          ...user,
          userRole: role.name,
        });

        this.successToast(i18next.t('grower:user_assign_success', messageData));
      } catch {
        this.errorToast(i18next.t('grower:user_assign_error', messageData));
      }
    }

    this.resellerUsers = [...assignedUsers, ...this.resellerUsers];
  };

  unassignUsersFromGrower = async (
    growerId: number,
    users: ResellerUsers[],
  ) => {
    const unassignedUsersIds = new Set<number>();

    // eslint-disable-next-line no-restricted-syntax
    for await (const user of users) {
      const messageData = {
        firstName: user.firstName,
        lastName: user.lastName,
      };

      try {
        await api.deleteUnassignUserGrowers(user.id, [growerId]);
        unassignedUsersIds.add(user.id);
        this.setSelectedRowsUsers({ selection: [], activeRow: null });
        this.successToast(
          i18next.t('grower:user_unassign_success', messageData),
        );
      } catch {
        this.errorToast(i18next.t('grower:user_unassign_error', messageData));
      }
    }

    this.resellerUsers = this.resellerUsers.filter(
      (user) => !unassignedUsersIds.has(user.id),
    );
  };

  createNewReseller = async () => {
    try {
      const grower = await api.postGrower(this.growerAction);
      this.resellerTree = await api.getAllGrowers();

      if (this.selectedTreeNode) {
        const growerChildTreeNode = {
          data: grower,
          children: [],
          key: `${grower.id}-${grower.id}`,
        };

        this.selectedTreeNode.children = [
          growerChildTreeNode,
          ...this.selectedTreeNode.children,
        ];
      }

      this.successToast(
        `${grower.growerType === GrowerType.Reseller ? 'Reseller' : 'Grower'
        } created successfully`,
      );
    } catch {
      this.errorToast(
        `Something get wrong, can't create new ${this.growerAction.growerType === GrowerType.Reseller
          ? 'reseller'
          : 'grower'
        }`,
      );
    }
  };

  editReseller = async () => {
    try {
      const grower = { ...this.selectedRows[0], ...this.growerAction };
      await api.putGrowers([grower]);
      this.successToast(
        `${this.growerAction.growerType === GrowerType.Reseller
          ? 'Reseller'
          : 'Grower'
        } edited successfully`,
      );
    } catch {
      this.errorToast(
        `Something get wrong, can't edit ${this.growerAction.growerType === GrowerType.Reseller
          ? 'reseller'
          : 'grower'
        }`,
      );
    }
  };

  uploadExcelPlots = async (file: File) => {
    try {
      const growerID = this.selectedTreeNode?.data?.id || null;
      const res = (await api.postUploadPlotsExcel(growerID, file)) || [];
      this.plotUploadExcel = res.map((plot: PlotExcel) => ({
        isValid: plot.importErrors.length === 0,
        selected: plot.importErrors.length === 0,
        plot: plot.plot,
        importErrors: plot.importErrors,
        importWarnings: plot.importWarnings,
      }));
    } catch (error) {
      if (error instanceof Error) {
        this.errorToast(`Plots excel file is not valid - ${error.message}`);
      } else {
        this.errorToast(`Something get wrong - please try later`);
      }
    }

    return this.plotUploadExcel;
  };

  createPlots = async () => {
    try {
      const growerID = this.selectedTreeNode?.data?.id || null;
      const response =
        (await api.postCreatePlotsFromJson(growerID, this.plotUploadExcel)) ||
        [];

      if (response) {
        this.invalidPlotsFileBlob = new Blob([response], {
          type: 'application/octet-stream',
        });

        this.invalidPlotsFileName = response.headers
          ? response.headers['Content-Disposition']?.split('filename=')[1]
          : 'invalid_plots.xlsx';

        if (growerID) {
          this.getResellerPlots(growerID, false);
        }
      }

      return true;
    } catch (error) {
      this.errorToast(`Something get wrong, while creating plots batch`);
    }

    return false;
  };

  setExcelPlotSelected = (plotId: number, selected: boolean) => {
    const found = this.plotUploadExcel.find((el) => el.plot?.id === plotId);
    if (found) {
      found.selected = selected;
    }
  };

  getResellersTreeGrowers = (excludeIds?: Set<number>): UserGrower[] => {
    const result: UserGrower[] = [];
    const traverse = (node: CustomTreeNode<ResellerTreeNode>) => {
      const isGrower = node.data?.growerType === GrowerType.Grower;
      const isExcluded = excludeIds?.has(node.data?.id as number);
      if (isGrower && !isExcluded) {
        result.push(node.data as UserGrower);
      }

      if (node.children) {
        node.children.forEach((child) => {
          traverse(child);
        });
      }
    };

    this.dataTree.forEach((node) => traverse(node));
    return result;
  };

  updatePlotStatus = async (status: PlotStatus | undefined) => {
    try {
      if (!status) {
        return;
      }

      this.selectedRowsPlots.forEach((plot) => {
        if (status) {
          plot.status = status;
        }
      });

      const res = await api.putUpdatePlotsStatus(
        this.selectedTreeNode?.data?.id || null,
        this.selectedRowsPlots,
      );

      if (res) {
        const plots = this.resellerPlots;
        plots.forEach((plot) => {
          if (this.selectedRowsPlots.includes(plot)) {
            plot.status = status;
          }
        });

        this.resellerPlots = [...plots];
        this.successToast(`Plots status updated successfully`);
        this.setSelectedRowsPlots([]);
      }
    } catch (error) {
      this.setSelectedRowsPlots([]);
      this.errorToast(`Something get wrong, while editing plots status`);
    }
  };

  addNewPlot = (plot: WebPlot) => {
    this.setSelectedRowsPlots([plot]);
    this.resellerPlots = [plot, ...this.resellerPlots];
  };

  editPlot = (plot: WebPlot) => {
    this.setSelectedRowsPlots([plot]);
    this.resellerPlots = this.resellerPlots.map((resellerPlot) =>
      resellerPlot.id === plot.id ? plot : resellerPlot,
    );
  };

  runAnomalyDetection = async (growerId: string | number) => {
    try {
      const res = await api.getRunAnomalyDetection(growerId);
      const responseContentType = res?.headers?.['content-type'];
      if (responseContentType === 'application/json') {
        if (res?.data) {
          this.successToast(`Anomaly Detection ran successfully`);
        }
      }

      if (responseContentType === 'application/octet-stream') {
        const responseFileName =
          res?.headers['content-disposition']?.split('filename=')[1];

        if (responseFileName) {
          displayFileOrDownload(res?.data, responseFileName);
        }
      }
    } catch (error) {
      this.errorToast(`Something get wrong, ${error}`);
    }
  };

  successToast = (message: string) => {
    this.rootStore?.snackBarStore.showToast({
      detail: message,
      severity: 'success',
      summary: 'Success',
    });
  };

  errorToast = (message: string) => {
    this.rootStore?.snackBarStore.showToast({
      detail: message,
      summary: 'error',
      severity: 'error',
    });
  };

  private getErrorMessage = (error: unknown): string => {
    return (
      getAxiosErrorMessage(error) ?? i18next.t('errors:something_went_wrong')
    );
  };

  private generateSensors = (sensor: Sensor): Sensor => {
    const modifiedSensor = { ...sensor };
    let resultSensor;
    if (modifiedSensor.params) {
      if (modifiedSensor.params?.alias) {
        modifiedSensor.alias = modifiedSensor.params.alias;
        delete modifiedSensor.params.alias;
      }

      if ('alias' in modifiedSensor.params) {
        delete modifiedSensor.params.alias;
      }

      const defaultSensors = veryLocalStorage.get(
        'defaultSensors',
      ) as ActiveSensor[];

      const defaultParams = defaultSensors.find(
        (item) => item.sensor.serial === modifiedSensor.serial,
      );

      if (defaultParams) {
        modifiedSensor.params = defaultParams.sensor.params || undefined;
        resultSensor = {
          ...defaultParams.sensor,
          alias: modifiedSensor.alias,
          displayName: modifiedSensor.displayName,
        };
      }
    }

    return resultSensor || modifiedSensor;
  };
}
