import Page from 'components/shared/Page';
import { observer } from 'mobx-react';
import React, {
  FC,
  useEffect,
  useState,
  useCallback,
  MouseEvent,
  useMemo,
  ChangeEvent,
} from 'react';
import Grid from 'components/UI/Grid';
import { ToggleBox, ToggleBoxProps } from 'components/shared/ToggleBox';
import { VisibilityControl } from 'components/shared/VisibilityControl';
import VerticalCollapse from 'components/shared/VerticalCollapse';
import styled from 'styled-components';
import { TabPanel, TabView, TabViewTabChangeEvent } from 'primereact/tabview';
import { useTranslation } from 'react-i18next';
import { useStores } from 'stores/hooks/hooks';
import i18next from 'i18next';
import { PlotTableViewMode, TabViewMode } from 'core/enums';
import Loading from 'components/UI/Loading/Loading';
import { GrowerType, ResellerTreeMode } from 'models/grower';
import VisualTabView, {
  VisualTabItem,
  VisualTabViewPanel,
} from 'components/shared/VisualTabView';
import Input from 'components/UI/Input';
import Text from 'components/UI/Text';
import Row from 'components/UI/Row';
import Space from 'components/UI/Space';
import ResellersTable from './ResellersTable/ResellersTable';
import MenuBar from './MenuBar';
import ResellersTree from './ResellersTree/ResellersTree';
import DetailsTable from './ResellerDetails/Details';
import ResellerPlots from './Plots/ResellerPlots';
import ResellerUsers from './Users/ResellerUsers';
import SystemsTable from './Systems/Systems';
import ResellersMap from './ResellersMap/ResellersMap';
import FeaturesTable from './Features/Features';
import ResellerMassEdit from './Plots/ResellerMassEditPlots/ResellerMassEdit';

const SelectedNodeTitle = styled(Text)`
  color: ${({ theme }) => theme.color.text};
  font-weight: 600;
`;

const StyledMarginGrid = styled(Grid)`
  margin: 1rem 1rem 0 0rem;
`;

const TabViewContainer = styled.div`
  flex-direction: column;
  position: relative;
  display: flex;
  width: 100%;
  margin-top: 1rem;
`;

const HeaderTabsContainer = styled.div`
  /* position: relative; */
  width: 100%;
  /* margin-top: 1rem; */
  display: flex;
  align-items: center;
  background: white;
  justify-content: space-between;
  padding: 0px 20px 0px 0px;
`;

const StyledInput = styled(Input)`
  background-color: ${(p) => p.theme.palette.background.default};
`;

export const TreeContainer = styled.div`
  height: calc(100vh - 106px);
  margin: 1rem;
  margin-right: 0;
  overflow: hidden;
`;

export enum ResellersGrowerTab {
  Details = 0,
  Plots = 1,
  Systems = 2,
  Features = 3,
  Growers = 4,
  Users = 5,
}

const plotsViewOptions: ToggleBoxProps['options'] = [
  { value: TabViewMode.Table, label: i18next.t('grower:table') },
  { value: TabViewMode.Map, label: i18next.t('grower:map') },
];

const plotsTableViewOptions: ToggleBoxProps['options'] = [
  {
    value: PlotTableViewMode.Metadata,
    label: i18next.t('grower:metadata_view'),
  },
  {
    value: PlotTableViewMode.MassEditing,
    label: i18next.t('grower:mass_edit_view'),
  },
];

const Resellers: FC = observer(() => {
  const { t } = useTranslation('grower');
  const { resellersStore } = useStores();
  const { selectedRowsPlots, selectedTreeNode } = resellersStore;

  const [isLoading, setIsLoading] = useState(true);

  const [activeTabItem, setActiveTabItem] = useState<{
    index: number;
    data: VisualTabItem;
  } | null>(null);

  const [activeIndex, setActiveIndex] = useState<ResellersGrowerTab>(
    ResellersGrowerTab.Details,
  );

  const [plotsViewMode, setPlotsViewMode] = useState<TabViewMode>(
    TabViewMode.Table,
  );

  const [plotsTableViewMode, setPlotsTableViewMode] =
    useState<PlotTableViewMode>(PlotTableViewMode.Metadata);

  const nodeTitle = selectedTreeNode?.data?.name;

  const growerType = resellersStore.selectedTreeNode?.data
    ?.growerType as GrowerType;

  const tabViewItems: VisualTabItem[] = useMemo(() => {
    switch (growerType) {
      case GrowerType.Grower:
        return [
          {
            header: t('details'),
            component: <DetailsTable />,
            tabId: ResellersGrowerTab.Details,
          },
          {
            header: t('plots'),
            component: (
              <>
                <VisibilityControl
                  visible={
                    plotsViewMode === TabViewMode.Table &&
                    plotsTableViewMode === PlotTableViewMode.Metadata
                  }
                >
                  <ResellerPlots />
                </VisibilityControl>
                <VisibilityControl
                  visible={
                    plotsViewMode === TabViewMode.Table &&
                    plotsTableViewMode === PlotTableViewMode.MassEditing
                  }
                >
                  {plotsViewMode === TabViewMode.Table &&
                    plotsTableViewMode === PlotTableViewMode.MassEditing && (
                      <ResellerMassEdit />
                    )}
                </VisibilityControl>
                <VisibilityControl visible={plotsViewMode === TabViewMode.Map}>
                  <ResellersMap />
                </VisibilityControl>
              </>
            ),
            tabId: ResellersGrowerTab.Plots,
          },
          {
            header: t('systems'),
            component: <SystemsTable />,
            tabId: ResellersGrowerTab.Systems,
          },
          {
            header: t('features'),
            component: <FeaturesTable />,
            tabId: ResellersGrowerTab.Features,
          },
          {
            header: t('users'),
            component: <ResellerUsers />,
            tabId: ResellersGrowerTab.Users,
          },
        ];
      case GrowerType.Reseller:
        return [
          {
            header: t('details'),
            component: <DetailsTable />,
            tabId: ResellersGrowerTab.Details,
          },
          {
            header: t('growers'),
            component: <ResellersTable />,
            tabId: ResellersGrowerTab.Growers,
          },
          {
            header: t('users'),
            component: <ResellerUsers />,
            tabId: ResellersGrowerTab.Users,
          },
        ];

      default:
        return [];
    }
  }, [growerType, plotsTableViewMode, plotsViewMode, t]);

  const onTabChangeTest = useCallback(
    (tabData: { index: number; data: VisualTabItem }) => {
      setActiveTabItem(tabData);
    },
    [],
  );

  useEffect(() => {
    if (growerType === GrowerType.Reseller) {
      const resellerTabIndex = tabViewItems.findIndex(
        (item) => item.tabId === ResellersGrowerTab.Growers,
      );

      setActiveTabItem({
        index: resellerTabIndex,
        data: tabViewItems[resellerTabIndex],
      });
    } else {
      const growerTabIndex = tabViewItems.findIndex(
        (item) => item.tabId === ResellersGrowerTab.Plots,
      );

      setActiveTabItem({
        index: growerTabIndex,
        data: tabViewItems[growerTabIndex],
      });
    }
  }, [growerType, tabViewItems]);

  useEffect(() => {
    resellersStore.initData().then(() => {
      setIsLoading(false);
    });

    return () => {
      resellersStore.setResellerTree([]);
    };
  }, [resellersStore]);

  const handlePlotsViewChange = useCallback(
    (_event: MouseEvent<HTMLElement>, value: TabViewMode) => {
      setPlotsViewMode((prev) => value ?? prev);
    },
    [],
  );

  const plotViewModeProps = useMemo(
    () => ({
      plotsViewOptions,
      plotViewMode: plotsViewMode,
      plotViewModeVisible:
        growerType === GrowerType.Grower &&
        activeTabItem?.data?.tabId === ResellersGrowerTab.Plots,
      plotViewOnChange: handlePlotsViewChange,
    }),
    [
      activeTabItem?.data?.tabId,
      growerType,
      handlePlotsViewChange,
      plotsViewMode,
    ],
  );

  const handlePlotsTableViewChange = useCallback(
    (_event: MouseEvent<HTMLElement>, value: PlotTableViewMode) => {
      setPlotsTableViewMode((prev) => value ?? prev);
    },
    [],
  );

  const plotTableViewModeProps = useMemo(
    () => ({
      toggleViewModeOptions: plotsTableViewOptions,
      toggleViewMode: plotsTableViewMode,
      toggleVisible:
        growerType === GrowerType.Grower &&
        activeTabItem?.data?.tabId === ResellersGrowerTab.Plots,
      toggleOnChange: handlePlotsTableViewChange,
    }),
    [
      activeTabItem?.data?.tabId,
      growerType,
      handlePlotsTableViewChange,
      plotsTableViewMode,
    ],
  );

  const handleSearchChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      resellersStore.setGlobalFilter((event.target.value || '').trim());
    },
    [resellersStore],
  );

  if (isLoading) {
    return (
      <Page>
        <Loading />
      </Page>
    );
  }

  return (
    <Page>
      <Grid direction="column" align="stretch" justify="start" grow>
        <VerticalCollapse
          leftSideWidth="400px"
          leftComponent={
            <TreeContainer>
              <ResellersTree mode={ResellerTreeMode.All} />
            </TreeContainer>
          }
          rightComponent={
            <TabViewContainer>
              <HeaderTabsContainer>
                <VisualTabView
                  onTabChange={onTabChangeTest}
                  activeIndex={activeTabItem?.index || 0}
                  data={tabViewItems}
                />
                <Row>
                  <SelectedNodeTitle>{nodeTitle}</SelectedNodeTitle>
                  <Space width={1} />
                  <StyledInput
                    size="small"
                    type="text"
                    placeholder={t('search')}
                    onChange={handleSearchChange}
                  />
                </Row>
              </HeaderTabsContainer>
              <StyledMarginGrid align="stretch">
                <MenuBar
                  selectedTab={activeTabItem?.data?.tabId || 0}
                  plotViewModeProps={plotViewModeProps}
                  plotTableViewModeProps={plotTableViewModeProps}
                />
              </StyledMarginGrid>
              <VisualTabViewPanel>
                {activeTabItem?.data?.component || null}
              </VisualTabViewPanel>
            </TabViewContainer>
          }
        />
      </Grid>
    </Page>
  );
});

export default Resellers;
