import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import DataTable from 'components/shared/DataTable';
import { Column } from 'components/shared/DataTable/DataTable';
import {
  AREA_UNITS,
  HALF_SCREEN_TABLE_TOP_HEIGHT,
  HIGHLIGHTED_ROW_CLASS_NAME,
  IRRIGATION_METHODS,
} from 'core/constants';
import { PlotStatus, WebPlot } from 'models/plot';
import useInactiveRowClassName from 'hooks/datatable/useInactiveRowClassName';
import Grid from 'components/UI/Grid';
import { useStores } from 'stores/hooks/hooks';
import { Paper } from '@mui/material';
import { FilterMatchMode } from 'primereact/api';
import Loading from 'components/UI/Loading/Loading';
import { AgeBody } from 'components/shared/Components';
import {
  getDataTableCheckboxFilterTemplate,
  getDataTableStatusColumnBody,
} from 'components/shared/DataTable/DataTableColumns';
import { DataTableRowClickEvent } from 'primereact/datatable';

const StyledGrid = styled(Grid)`
  display: flex;
  flex: unset;
  width: 100%;
  align-items: normal;
`;

const DataTableWrapper = styled(Paper)`
  max-height: ${HALF_SCREEN_TABLE_TOP_HEIGHT};
  overflow: hidden;

  .${HIGHLIGHTED_ROW_CLASS_NAME} {
    td {
      background-color: ${({ theme }) => theme.color.paleYellow};
    }
  }
`;

const areaIdAbbreviationsMap: Record<string, string> = AREA_UNITS.reduce(
  (acc, { id, shortName }) => ({ ...acc, [id]: shortName }),
  {},
);

const formatDecimal = (value: number | null): number =>
  parseFloat(value?.toFixed(2) as string);

const globalFilterFields = [
  'id',
  'name',
  'cropName',
  'variety',
  'serviceLevel',
  'irrigationMethod',
  'soilName',
  'growthMethod',
  'coordinates',
];

interface PlotTableRow extends WebPlot {
  coordinates: string;
  hasPolygon: boolean;
}

const Plots: FC = observer(() => {
  const { t } = useTranslation('grower');
  const { resellersStore } = useStores();
  const [filters, setFilters] = useState({
    global: { value: '', matchMode: FilterMatchMode.CONTAINS },
    id: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    cropName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    variety: { value: null, matchMode: FilterMatchMode.CONTAINS },
    serviceLevel: { value: null, matchMode: FilterMatchMode.CONTAINS },
    irrigationMethod: { value: null, matchMode: FilterMatchMode.CONTAINS },
    soilName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    hasPolygon: { value: null, matchMode: FilterMatchMode.EQUALS },
    growthMethod: { value: null, matchMode: FilterMatchMode.CONTAINS },
    coordinates: { value: null, matchMode: FilterMatchMode.CONTAINS },
    'area.val': { value: null, matchMode: FilterMatchMode.CONTAINS },
    age: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });

  useEffect(() => {
    setFilters((prev) => {
      prev.global.value = resellersStore.globalFilter.trim();
      return { ...prev };
    });
  }, [resellersStore.globalFilter]);

  const checkActive = useCallback(
    (plot: WebPlot) => plot.status === PlotStatus.Active,
    [],
  );

  const { getRowClassName } = useInactiveRowClassName({
    checkActive,
    rowClassName: HIGHLIGHTED_ROW_CLASS_NAME,
  });

  const getAreaBody = useCallback(
    ({ area }: WebPlot) =>
      area ? `${area.val} ${areaIdAbbreviationsMap[area.unit as string]}` : '',
    [],
  );

  const getPolygonBody = useCallback(
    (plot: PlotTableRow) => getDataTableStatusColumnBody(plot, 'geojson'),
    [],
  );

  const getPlotSensors = useCallback(
    (plotId: number) => {
      resellersStore.getAvailablePlotSensors(plotId);
    },
    [resellersStore],
  );

  const getIrrigationBody = useCallback(
    ({ irrigationMethod }: WebPlot) =>
      IRRIGATION_METHODS.find(({ value }) => value === irrigationMethod)
        ?.label ?? irrigationMethod,
    [],
  );

  const onRowClick = useCallback(
    ({ data }: DataTableRowClickEvent) => {
      const isSame = resellersStore.selectedRowsPlots.find(
        (plot) => plot.id === data.id,
      );

      if (isSame) {
        resellersStore.setSelectedRowsPlots([]);
      } else {
        const selectedPlot = resellersStore.resellerPlots.find(
          (plot) => plot.id === data.id,
        );

        resellersStore.setSelectedRowsPlots(selectedPlot ? [selectedPlot] : []);
        getPlotSensors(data.id);
      }
    },
    [resellersStore, getPlotSensors],
  );

  const tableRows: PlotTableRow[] = useMemo(
    () =>
      resellersStore.resellerPlots.map((plot) => ({
        ...plot,
        hasPolygon: !!plot.geojson,
        coordinates: `${formatDecimal(plot.latitude)}, ${formatDecimal(
          plot.longitude,
        )}`,
      })),
    [resellersStore.resellerPlots],
  );

  if (resellersStore.resellerPlotsLoading) {
    return <Loading />;
  }

  return (
    <StyledGrid flex={1}>
      <DataTableWrapper elevation={3}>
        <DataTable
          showGridlines
          resizableColumns
          scrollable
          stripedRows
          dataKey="id"
          id="ResellerPlotsTable"
          selectionMode="single"
          filterDisplay="row"
          value={tableRows}
          selection={resellersStore.selectedRowsPlots}
          scrollHeight={HALF_SCREEN_TABLE_TOP_HEIGHT}
          emptyMessage={t('empty_message')}
          onRowClick={onRowClick}
          rowClassName={getRowClassName}
          filters={filters}
          globalFilterFields={globalFilterFields}
        >
          <Column sortable filter field="id" header={t('plot_id')} />
          <Column sortable filter field="name" header={t('plot_name')} />
          <Column sortable filter field="coordinates" header={t('location')} />
          <Column
            sortable
            filter
            field="hasPolygon"
            header={t('polygon')}
            body={getPolygonBody}
            filterElement={getDataTableCheckboxFilterTemplate}
          />
          <Column sortable filter field="cropName" header={t('crop')} />
          <Column sortable filter field="variety" header={t('variety')} />
          <Column
            sortable
            filter
            field="serviceLevel"
            header={t('service_level')}
          />
          <Column
            sortable
            filter
            field="irrigationMethod"
            header={t('irrigation_method')}
            body={getIrrigationBody}
          />
          <Column sortable filter field="soilName" header={t('soil')} />
          <Column
            sortable
            filter
            field="growthMethod"
            header={t('growing_method')}
          />
          <Column
            sortable
            filter
            field="area.val"
            header={t('area')}
            body={getAreaBody}
          />
          <Column
            sortable
            filter
            field="age"
            header={t('age')}
            body={AgeBody}
          />
        </DataTable>
      </DataTableWrapper>
    </StyledGrid>
  );
});

export default Plots;
