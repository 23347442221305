import React, { FC, useCallback, useMemo, useState } from 'react';
import ReactJson from 'react-json-view';
import {
  DialogActions,
  DialogContent,
  Divider,
  TextField,
} from '@mui/material';
import Grid from 'components/UI/Grid';
import Dialog from 'components/UI/Dialog';
import Button from 'components/UI/Button';
import Text from 'components/UI/Text';
// import { Column } from 'components/shared/DataTable/DataTable';
import {
  DataTableRowClickEvent,
  DataTableStateEvent,
} from 'primereact/datatable';
import { Column, ColumnFilterElementTemplateOptions } from 'primereact/column';
import {
  GqlPlotAlertsPaginated,
  GqlPlotAlertsPaginatedContent,
} from 'gql/types/plots';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { AlertSeverity, AlertVariant } from 'models/alert';
import DataTable from 'components/shared/DataTable';
import {
  TABLE_ALERTS_MAX_ROWS_NUMBER,
  getAlertTypeColumnBody,
  getAlertsDateColumnBody,
  getIgnoreColumnBody,
  getSensorNameColumnBody,
  getSensorSerialColumnBody,
  getSensorTypeColumnBody,
  getSystemSerialColumnBody,
} from './AlertsTable.utils';

const Container = styled(Grid)`
  width: 100%;
  height: 100%;
  padding-left: 1rem;
`;

const DataTableContainer = styled.div`
  display: block;
  height: 100%;
  overflow: hidden;
`;

// Tried using global styles for these classes, but it put them in the wrong order
const StyledDataTable = styled(DataTable)`
  .data-table-params-column {
    max-width: 120px;
  }

  &&& {
    tr {
      &.p-table-row-background-red {
        background-color: ${(p) => p.theme.color.alertLightRed};
      }

      &.p-table-row-background-yellow {
        background-color: ${(p) => p.theme.color.alertLightYellow};
      }

      &.p-table-row-background-green {
        background-color: ${(p) => p.theme.color.alertLightGreen};
      }
    }
  }
`;

const ColumnButton = styled(Button)`
  width: 100%;
  color: ${({ theme }) => theme.color.text};
  &:hover {
    background-color: ${({ theme }) => theme.color.border};
  }
`;

const FilterTextField = styled(TextField)`
  background-color: ${({ theme }) => theme.color.white};
`;

const ParamsDialogContent = styled(DialogContent)`
  min-height: 30vh;
`;

const tableScrollHeight = '100%';

const columnStyle = { minWidth: '20px', maxWidth: '140px' };

export type AlertTableFilters = Pick<
  GqlPlotAlertsPaginatedContent,
  'alertSubType' | 'alertType' | 'plotName'
>;

interface AlertsTableProps {
  isReseller: boolean;
  alerts: GqlPlotAlertsPaginated;
  loading: boolean;
  tableParams: DataTableStateEvent;
  alertFilters: AlertTableFilters;
  variant: AlertVariant;
  onPageChange(params: DataTableStateEvent): void;
  onAlertFilterChange(key: keyof AlertTableFilters, value: string): void;
}

const AlertsTable: FC<AlertsTableProps> = observer(
  ({
    isReseller,
    alerts,
    loading,
    tableParams,
    alertFilters,
    variant,
    onPageChange,
    onAlertFilterChange,
  }) => {
    const { t } = useTranslation('grower');
    const { t: alertTableT } = useTranslation('liveMonitoring', {
      keyPrefix: 'technicalAlertsTable',
    });

    const { t: alertT } = useTranslation('alertTypes');
    const [selection, setSelection] = useState<GqlPlotAlertsPaginatedContent[]>(
      [],
    );

    const [isParamsDialogVisible, setParamsDialogVisible] =
      useState<boolean>(false);

    const [paramsJSON, setParamsJSON] = useState<Record<
      string,
      unknown
    > | null>(null);

    const onRowClick = useCallback(
      ({ data }: DataTableRowClickEvent) => {
        console.log('data', data);
        const isSame = selection[0]?.alertId === data.alertId;
        setSelection(isSame ? [] : [data]);
      },
      [selection],
    );

    const getParamsColumnBody = useCallback(
      ({ alertParamsJson }: GqlPlotAlertsPaginatedContent) => (
        <ColumnButton
          variant="text"
          onClick={() => {
            setParamsJSON(alertParamsJson ? JSON.parse(alertParamsJson) : null);
            setParamsDialogVisible(true);
          }}
        >
          ...
        </ColumnButton>
      ),
      [],
    );

    const hideParamsDialog = useCallback(() => {
      setParamsDialogVisible(false);
      setParamsJSON(null);
    }, []);

    const getAlertFilterTemplate = useCallback(
      ({ field }: ColumnFilterElementTemplateOptions) => {
        const key = field as keyof AlertTableFilters;
        return (
          <FilterTextField
            value={alertFilters?.[key]}
            onChange={({ target }) => {
              onAlertFilterChange(key, target.value);
            }}
            variant="outlined"
            size="small"
          />
        );
      },
      [alertFilters, onAlertFilterChange],
    );

    const getDescriptionColumnBody = useCallback(
      ({ alertType }: GqlPlotAlertsPaginatedContent) => {
        if (!alertType) {
          return '';
        }

        return alertT(`${alertType}.description`);
      },
      [alertT],
    );

    const rowClass = useCallback((data: GqlPlotAlertsPaginatedContent) => {
      return {
        'p-table-row-background-red': data.urgency === AlertSeverity.High,
        'p-table-row-background-yellow': data.urgency === AlertSeverity.Medium,
        'p-table-row-background-green': data.urgency === AlertSeverity.Low,
      };
    }, []);

    const totalRecords = alerts?.pageAlerts?.totalCount ?? 0;

    return (
      <Container display="block">
        <DataTableContainer>
          <StyledDataTable
            stripedRows
            lazy
            scrollable
            showGridlines
            resizableColumns
            id="AlertsTable"
            selectionMode="single"
            dataKey="alertId"
            filterDisplay="row"
            rowClassName={rowClass}
            value={alerts?.pageAlerts?.content ?? []}
            selection={selection}
            scrollHeight="flex"
            columnResizeMode="expand"
            tableStyle={{ minWidth: '1rem' }}
            emptyMessage={t('empty_message')}
            paginator={totalRecords > TABLE_ALERTS_MAX_ROWS_NUMBER}
            first={tableParams.first}
            rows={tableParams.rows}
            totalRecords={totalRecords}
            onPage={onPageChange}
            onRowClick={onRowClick}
            loading={loading}
          >
            {isReseller && (
              <Column
                style={columnStyle}
                field="growerName"
                header={alertTableT('grower_name')}
                sortable
                filter
              />
            )}
            <Column
              field="plotName"
              style={columnStyle}
              header={alertTableT('plot_name')}
              filterElement={getAlertFilterTemplate}
              sortable
              filter
            />
            {/* <Column field="alertId" header={alertTableT('alert_id')} sortable filter /> */}
            {variant === AlertVariant.Technical && (
              <Column
                style={columnStyle}
                field="alertType"
                header={alertTableT('alert_description')}
                body={getDescriptionColumnBody}
                sortable
                filter
              />
            )}
            {/* <Column
              field="alertSetTime"
              header={alertTableT('set_date')}
              body={getAlertsDateColumnBody}
              sortable
              filter
            /> */}
            {/* <Column field="alertState" header={alertTableT('status')} sortable filter /> */}
            {variant === AlertVariant.Technical && (
              <Column
                style={columnStyle}
                field="sensorName"
                header={alertTableT('sensor_name')}
                body={getSensorNameColumnBody}
                sortable
                filter
              />
            )}
            {variant === AlertVariant.Technical && (
              <Column
                style={columnStyle}
                field="sensorType"
                header={alertTableT('sensor_type')}
                body={getSensorTypeColumnBody}
                sortable
                filter
              />
            )}
            {variant === AlertVariant.Technical && (
              <Column
                style={columnStyle}
                field="sensorSerial"
                header={alertTableT('sensor_serial')}
                body={getSensorSerialColumnBody}
                sortable
                filter
              />
            )}
            {variant === AlertVariant.Technical && (
              <Column
                style={columnStyle}
                field="systemSerial"
                header={alertTableT('system_serial')}
                body={getSystemSerialColumnBody}
                sortable
                filter
              />
            )}
            {variant === AlertVariant.Technical && (
              <Column
                style={columnStyle}
                field="urgency"
                header={alertTableT('alert_urgency')}
                sortable
                filter
              />
            )}
            {variant === AlertVariant.Technical && (
              <Column
                style={columnStyle}
                field="sensorDetails"
                header={alertTableT('alert_details')}
                sortable
                filter
              />
            )}
            {variant === AlertVariant.Technical && (
              <Column
                style={columnStyle}
                field="alertSetTime"
                header={alertTableT('alert_detection_time')}
                sortable
                filter
              />
            )}
          </StyledDataTable>
        </DataTableContainer>
        <Dialog
          open={isParamsDialogVisible}
          onClose={hideParamsDialog}
          fullWidth
          maxWidth="lg"
          dialogTitle={alertTableT('alert_params')}
          titleWeight="bold"
          disableEscapeKeyDown
        >
          <ParamsDialogContent>
            {!!paramsJSON && <ReactJson src={paramsJSON} />}
          </ParamsDialogContent>
          <Divider />
          <DialogActions>
            <Button onClick={hideParamsDialog}>
              <Text size="sm">{t('close')}</Text>
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    );
  },
);

export default AlertsTable;
