import React, { FC, useCallback, useMemo, useState } from 'react';
import ReactJson from 'react-json-view';
import {
  DialogActions,
  DialogContent,
  Divider,
  TextField,
} from '@mui/material';
import Grid from 'components/UI/Grid';
import Dialog from 'components/UI/Dialog';
import Button from 'components/UI/Button';
import Text from 'components/UI/Text';
import { Column, DataTable } from 'components/shared/DataTable/DataTable';
import {
  DataTableStateEvent,
  DataTableRowClickEvent,
} from 'primereact/datatable';
import { ColumnFilterElementTemplateOptions } from 'primereact/column';
import {
  GqlPlotAlertsPaginatedContent,
  GqlAnomalyAlertsPaginatedContent,
  GqlAnomalyAlertsPaginated,
} from 'gql/types/plots';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { AlertSeverity, AlertVariant } from 'models/alert';
import {
  TABLE_ALERTS_MAX_ROWS_NUMBER,
  getSensorNameColumnBody,
  getSensorSerialColumnBody,
  getSensorTypeColumnBody,
  getSystemSerialColumnBody,
} from '../AlertsTable.utils';

const Container = styled(Grid)`
  width: 100%;
  height: 100%;
  padding-left: 1rem;
`;

const DataTableContainer = styled.div`
  display: block;
  height: 100%;
  overflow: hidden;
`;

const StyledDataTable = styled(DataTable)`
  .data-table-params-column {
    max-width: 120px;
  }

  &&& {
    tr {
      &.p-table-row-background-red {
        background-color: ${(p) => p.theme.color.alertLightRed};
      }

      &.p-table-row-background-yellow {
        background-color: ${(p) => p.theme.color.alertLightYellow};
      }

      &.p-table-row-background-green {
        background-color: ${(p) => p.theme.color.alertLightGreen};
      }
    }
  }
`;

const ColumnButton = styled(Button)`
  width: 100%;
  color: ${({ theme }) => theme.color.text};
  &:hover {
    background-color: ${({ theme }) => theme.color.border};
  }
`;

const FilterTextField = styled(TextField)`
  background-color: ${({ theme }) => theme.color.white};
`;

const ParamsDialogContent = styled(DialogContent)`
  min-height: 30vh;
`;

const tableScrollHeight = '100%';

export type AlertTableFilters = Pick<
  GqlAnomalyAlertsPaginatedContent,
  'plotName'
>;

interface AlertsTableProps {
  isReseller: boolean;
  alerts: GqlAnomalyAlertsPaginated;
  loading: boolean;
  tableParams: DataTableStateEvent;
  alertFilters: AlertTableFilters;
  variant: AlertVariant;
  onPageChange(params: DataTableStateEvent): void;
  onAlertFilterChange(key: keyof AlertTableFilters, value: string): void;
}

const AnomalyAlertsTable: FC<AlertsTableProps> = observer(
  ({
    isReseller,
    alerts,
    loading,
    tableParams,
    alertFilters,
    onPageChange,
    onAlertFilterChange,
  }) => {
    const { t } = useTranslation('grower');
    const { t: alertTableT } = useTranslation('liveMonitoring', {
      keyPrefix: 'anomalyAlertsTable',
    });

    const [selection, setSelection] = useState<
      GqlAnomalyAlertsPaginatedContent[]
    >([]);

    const [isParamsDialogVisible, setParamsDialogVisible] =
      useState<boolean>(false);

    const [paramsJSON, setParamsJSON] = useState<Record<
      string,
      unknown
    > | null>(null);

    const onRowClick = useCallback(
      ({ data }: DataTableRowClickEvent) => {
        // cant select Anomaly alert since they have no ID
        // const isSame = selection[0]?.alertId === data.alertId;
        // setSelection(isSame ? [] : [data]);
      },
      [selection],
    );

    const hideParamsDialog = useCallback(() => {
      setParamsDialogVisible(false);
      setParamsJSON(null);
    }, []);

    const getAlertFilterTemplate = useCallback(
      ({ field }: ColumnFilterElementTemplateOptions) => {
        const key = field as keyof AlertTableFilters;
        return (
          <FilterTextField
            value={alertFilters?.[key]}
            onChange={({ target }) => {
              onAlertFilterChange(key, target.value);
            }}
            variant="outlined"
            size="small"
          />
        );
      },
      [alertFilters, onAlertFilterChange],
    );

    const rowClass = useCallback((data: GqlAnomalyAlertsPaginatedContent) => {
      return {
        'p-table-row-background-red': data.urgency === AlertSeverity.High,
        'p-table-row-background-yellow': data.urgency === AlertSeverity.Medium,
        'p-table-row-background-green': data.urgency === AlertSeverity.Low,
      };
    }, []);

    const totalRecords = alerts?.anomalyDetection?.totalCount ?? 0;

    return (
      <Container flex={1} display="block">
        <DataTableContainer>
          <StyledDataTable
            stripedRows
            lazy
            scrollable
            showGridlines
            resizableColumns
            id="AlertsTable"
            selectionMode="single"
            dataKey="alertId"
            rowClassName={rowClass}
            filterDisplay="row"
            value={alerts?.anomalyDetection?.content ?? []}
            selection={selection}
            scrollHeight="flex"
            emptyMessage={t('empty_message')}
            paginator={totalRecords > TABLE_ALERTS_MAX_ROWS_NUMBER}
            first={tableParams.first}
            rows={tableParams.rows}
            totalRecords={totalRecords}
            onPage={onPageChange}
            onRowClick={onRowClick}
            loading={loading}
          >
            {isReseller && (
              <Column
                field="growerName"
                header={alertTableT('grower_name')}
                sortable
                filter
              />
            )}
            <Column
              field="plotName"
              header={alertTableT('plot_name')}
              filterElement={getAlertFilterTemplate}
              sortable
              filter
            />
            <Column
              field="description"
              header={alertTableT('alert_description')}
              sortable
              filter
            />
            <Column
              field="sensorName"
              header={alertTableT('sensor_name')}
              body={getSensorNameColumnBody}
              sortable
              filter
            />
            <Column
              field="sensorType"
              header={alertTableT('sensor_type')}
              body={getSensorTypeColumnBody}
              sortable
              filter
            />
            <Column
              field="sensorSerial"
              header={alertTableT('sensor_serial')}
              body={getSensorSerialColumnBody}
              sortable
              filter
            />
            <Column
              field="systemSerial"
              header={alertTableT('system_serial')}
              body={getSystemSerialColumnBody}
              sortable
              filter
            />
            <Column
              field="urgency"
              header={alertTableT('alert_urgency')}
              sortable
              filter
            />
            <Column
              field="sensorDetails"
              header={alertTableT('alert_details')}
              sortable
              filter
            />
            <Column
              field="fromTimestamp"
              header={alertTableT('alert_detection_time')}
              sortable
              filter
            />
            <Column
              field="alertCount"
              header={alertTableT('alert_count')}
              sortable
              filter
            />
          </StyledDataTable>
        </DataTableContainer>
        <Dialog
          open={isParamsDialogVisible}
          onClose={hideParamsDialog}
          fullWidth
          maxWidth="lg"
          dialogTitle={alertTableT('alert_params')}
          titleWeight="bold"
          disableEscapeKeyDown
        >
          <ParamsDialogContent>
            {!!paramsJSON && <ReactJson src={paramsJSON} />}
          </ParamsDialogContent>
          <Divider />
          <DialogActions>
            <Button onClick={hideParamsDialog}>
              <Text size="sm">{t('close')}</Text>
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    );
  },
);

export default AnomalyAlertsTable;
