import styled from 'styled-components';

interface VisibilityControlProps {
  visible: boolean;
}

export const VisibilityControl = styled.div<VisibilityControlProps>`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  overflow: hidden;
`;
